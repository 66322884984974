import { Form } from 'react-bootstrap';

function LegendPositionControl({ onChange }) {
    return (
        <Form>
            <Form.Group>
                <Form.Label className='text-secondary'>Select Legend Position</Form.Label>
                <Form.Control as="select" onChange={(e) => onChange(e.target.value)}>
                    <option value="bottom">Bottom</option>
                    <option value="top">Top</option>
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                </Form.Control>
            </Form.Group>
        </Form>
    );
}

export default LegendPositionControl;
