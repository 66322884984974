import React from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, LinearScale, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, LinearScale, CategoryScale);

const TorqueChart = ({ legendPosition, results, parameters, frictionFactors, selectedFactors }) => {
    // Prepare data for the chart
    const depthData = results.map((row) => row.d_depth);
    const inclinationData = results.map((row) => row.inclinationBottom);
    const torqueRotOffBtmData = results.map((row) => row.torqueRotOffBtm);
    const torqueRotOnBtmBData = results.map((row) => row.torqueRotOnBtm);

    // fricttion factors
    const muRotatingOffBottomCh = frictionFactors.casedHole.rotatingOffBottom;
    const muRotatingOnBottomCh = frictionFactors.casedHole.rotatingOnBottom;

    const muRotatingOffBottomOh = frictionFactors.openHole.rotatingOffBottom;
    const muRotatingOnBottomOh = frictionFactors.openHole.rotatingOnBottom;

    const chartData = {
        datasets: [
            {
                label: "Inclination",
                data: depthData.map((depth, i) => ({
                    x: inclinationData[i],
                    y: depth,
                })),
                borderColor: "blue",
                backgroundColor: "blue",
                showLine: true, // Adds the connecting line
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: `On-bottom torque CH: ${muRotatingOnBottomCh}, OH: ${muRotatingOnBottomOh}`,
                data: depthData.map((depth, i) => ({
                    x: torqueRotOnBtmBData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "green",
                backgroundColor: "green",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: `Off-bottom torque CH: ${muRotatingOffBottomCh}, OH: ${muRotatingOffBottomOh}`,
                data: depthData.map((depth, i) => ({
                    x: torqueRotOffBtmData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "red",
                backgroundColor: "red",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: legendPosition,
                labels: {
                    boxWidth: 10, // Adjust the width of the legend box
                    boxHeight: 3, // Adjust the height of the legend box
                    padding: 10, // Optional: Adjust spacing around legend items
                },

            },
            title: {
                display: true,
                text: "Inclination, Torque vs. Depth",
            },
            datalabels: false,
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Inclination, Torque",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Depth",
                },
                reverse: true, // Reverse depth axis
            },
        },
    };

    return (
        <div style={{ height: "500px" }}>
            <Scatter data={chartData} options={chartOptions} />
        </div>
    );
};

export default TorqueChart;
