import React, { useState } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

// Helper function to round numbers
const round = (value, precision) => {
    return Number(value).toFixed(precision);
};

// Function to calculate CSC based on equipment type
function calculateCSC(surfEquipmentType) {
    let c_sc = 0;
    const normalizedType = surfEquipmentType?.trim(); // Trim whitespace

    if (normalizedType === 'Class 1') {
        c_sc = 1; // Based on API 14B - proportionality constant Csc on page 27
    } else if (normalizedType === 'Case 2') {
        c_sc = 0.36;
    } else if (normalizedType === 'Case 3') {
        c_sc = 0.22;
    } else if (normalizedType === 'Case 4') {
        c_sc = 0.15;
    } else if (normalizedType === 'Case 5') {
        c_sc = 0.15;
    }

    return c_sc;
}

const SurfaceEquipmentPressureLossCalculator = () => {
    const [surfEquipmentType, setSurfEquipmentType] = useState('');
    const [cumRate, setCumRate] = useState('');
    const [mw, setMw] = useState('');
    const [surfPressLoss, setSurfPressLoss] = useState(null);

    const calculateSurfPressLoss = () => {
        // Parse the input values as numbers
        const rate = parseFloat(cumRate);
        console.log('cum rate', rate);

        const weight = parseFloat(mw);

        if (isNaN(rate) || isNaN(weight) || !surfEquipmentType) {
            alert("Please enter valid numeric values and select a surface equipment type.");
            return;
        }

        const c_sc = calculateCSC(surfEquipmentType);
        const result = round(c_sc * weight * ((rate / 100) ** 1.86), 3);
        setSurfPressLoss(result);
    };

    return (
        <Container>
            <h3>Surface Equipment Pressure Loss Calculator</h3>
            <Form>
                <Row className="mb-3">
                    <Col md={4}>
                        <Form.Group controlId="surfEquipmentType">
                            <Form.Label>Surface Equipment Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={surfEquipmentType}
                                onChange={(e) => setSurfEquipmentType(e.target.value)}
                            >
                                <option value="">Select Case</option>
                                <option value="Case 1">Case 1</option>
                                <option value="Case 2">Case 2</option>
                                <option value="Case 3">Case 3</option>
                                <option value="Case 4">Case 4</option>
                                <option value="Case 5">Case 5</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="cumRate">
                            <Form.Label>Cumulative Rate (cum_rate)</Form.Label>
                            <Form.Control
                                type="number"
                                value={cumRate}
                                onChange={(e) => setCumRate(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="mw">
                            <Form.Label>Mud Weight (MW)</Form.Label>
                            <Form.Control
                                type="number"
                                value={mw}
                                onChange={(e) => setMw(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" onClick={calculateSurfPressLoss}>
                    Calculate Surface Equipment Pressure Loss
                </Button>
            </Form>

            {surfPressLoss !== null && (
                <div className="mt-3">
                    <h4>Surface Equipment Pressure Loss: {surfPressLoss} psi</h4>
                </div>
            )}
        </Container>
    );
};

export default SurfaceEquipmentPressureLossCalculator;
