import React, { useState, useEffect } from "react";
import axios from "axios";
import TwoFactorSettings from "./admin/TwoFactorSettings";
import PasswordChange from "./admin/PasswordChange";
// import LogoUploader from "./admin/LogoUploader";
import { Button, Col, Row, Spinner, Tabs, Tab, Image, Card, Form } from "react-bootstrap";
import CancelSubscription from "./admin/CancelSubscription";
import SubscriptionPage from "./admin/SubscriptionPage";
import UserOrderHistory from "./admin/UserOrderHistory";

function Profile() {
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [profileData, setProfileData] = useState({
        profileId: "",
        profile_email: "",
        profileName: "",
        profileJobTitle: "",
        profileCompany: "",
        profilePhoneNumber: "",
        profileLogoPath: "",
        profilLogoExists: false,
    });
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [logoFile, setLogoFile] = useState(null);

    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const response = await axios.get(`${baseURL}/profile`, {
                    withCredentials: true,
                });
                const { user_id, email, full_name, job_title, company_name, phone_number, logo_path } = response.data;

                setProfileData({
                    profileId: user_id,
                    profile_email: email,
                    profileName: full_name,
                    profileJobTitle: job_title,
                    profileCompany: company_name,
                    profilePhoneNumber: phone_number,
                    profilLogoExists: !!logo_path,
                    profileLogoPath: logo_path ? `${baseURL}/${logo_path}` : "",
                });
                
            } catch (error) {
                console.error("Error fetching user profile:", error.response?.data || error.message);
            } finally {
                setIsLoading(false);
            }
        };
        getUserProfile();
    }, [baseURL]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleLogoChange = (e) => {
        setLogoFile(e.target.files[0]);
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleSaveProfile = async () => {
        try {
            const formData = new FormData();
            formData.append("full_name", profileData.profileName);
            formData.append("job_title", profileData.profileJobTitle);
            formData.append("company_name", profileData.profileCompany);
            formData.append("phone_number", profileData.profilePhoneNumber);

            if (logoFile) {
                formData.append("logo", logoFile);
            }

            const response = await axios.put(`${baseURL}/profile`, formData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                console.log("Profile updated successfully!");
                setIsEditing(false);
                if (response.data.logo_path) {
                    setProfileData((prevData) => ({
                        ...prevData,
                        profileLogoPath: `${baseURL}/${response.data.logo_path}`,
                        profilLogoExists: true,
                    }));
                }
            }
        } catch (error) {
            console.error("Error updating profile:", error.response?.data || error.message);
        }
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" />
            </div>
        );
    }

    return (
        <div className="container min-vh-100 py-4 bg-white">
            <Row className="g-4">
                <Col md={4}>
                    <Card className="shadow-sm">
                        <Card.Body className="text-center">
                            <Image
                                src={profileData.profileLogoPath || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
                                roundedCircle
                                width="150"
                                height="150"
                                className="mb-4"
                            />
                            {isEditing ? (
                                <Form.Group className="mb-3">
                                    <Form.Label>Upload Logo</Form.Label>
                                    <Form.Control type="file" accept="image/*" onChange={handleLogoChange} />
                                </Form.Group>
                            ) : null}
                            {isEditing ? (
                                <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="profileName"
                                            value={profileData.profileName}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Job Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="profileJobTitle"
                                            value={profileData.profileJobTitle}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Company</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="profileCompany"
                                            value={profileData.profileCompany}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleSaveProfile} className="me-2">
                                        Save
                                    </Button>
                                    <Button variant="secondary" onClick={handleEditToggle}>
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <h5>{profileData.profileName}</h5>
                                    <p className="text-muted mb-1">{profileData.profileJobTitle}</p>
                                    <p className="text-muted mb-1">{profileData.profileCompany}</p>
                                    <p className="text-muted">{profileData.profile_email}</p>
                                    <Button variant="primary" onClick={handleEditToggle}>
                                        Edit Profile
                                    </Button>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={8}>
                    <Card className="shadow-sm">
                        <Card.Body>
                            <Tabs defaultActiveKey="subscriptions" id="profile-tabs" className="mb-3">
                                <Tab eventKey="settings" title="Settings">
                                    <TwoFactorSettings />
                                    <div className="mt-4">
                                        <PasswordChange />
                                    </div>
                                    
                                </Tab>

                                <Tab eventKey="subscriptions" title="Subscriptions">
                                    <Row className="mb-3">
                                        <Col>
                                            <CancelSubscription userId={profileData.profileId} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <SubscriptionPage userId={profileData.profileId} />
                                        </Col>
                                    </Row>
                                    
                                </Tab>
                                <Tab eventKey="orderHistory" title="Order History">
                                    <p>Your order history</p>
                                    <UserOrderHistory />
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Profile;
