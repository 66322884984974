import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Modal, Button, Alert, Form } from "react-bootstrap";
import AddBitForm from "./addBitForm"; // Ensure this is correctly imported
import EditBitModal from "./editBitModal"; // Ensure this is correctly imported
import { GlobalStateContext } from "../../GlobalStateContext";
import { useHoleSection } from "../../HoleSectionProvider";

export default function BitTable(props) {
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [allBitData, setAllBitData] = useState([]);
    const [filteredBits, setFilteredBits] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [drillBitIdToDelete, setDrillBitIdToDelete] = useState(null);
    const [showAddBitModal, setShowAddBitModal] = useState(false);
    const [showEditBitModal, setShowEditBitModal] = useState(false);
    const [bitToEdit, setBitToEdit] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [showAddBitSuccess, setShowAddBitSuccess] = useState(false);
    const { selectedHoleItems } = useHoleSection();
    const holeSize = selectedHoleItems?.openHoleParameters?.hole_size;

    console.log('selectedHoleItems', selectedHoleItems);
    console.log('holeSize', holeSize);

    useEffect(() => {
        axios.get(`${baseURL}/input/bit_id/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`, {
            withCredentials: true,
        })
            .then(response => {
                const all_bit_data = response.data;
                setAllBitData(all_bit_data);

                console.log('all_bit_data', all_bit_data);

                // Filter bits based on holeSize condition
                const filtered = all_bit_data.filter(bit => bit.bit_size <= holeSize);
                setFilteredBits(filtered);

                // Automatically set default if only one bit matches
                
                // if (filtered.length === 1) {
                //     setSelectedItems(prevState => ({
                //         ...prevState,
                //         default_bit_id: filtered[0].drill_bit_id,
                //     }));
                // }

                // find bit corresponding to default bit
                const parameters = filtered[0];

                if (filtered.length === 1) {
                    setSelectedItems(prevState => ({
                        ...prevState,
                        default_bit_id: filtered[0].drill_bit_id,
                        bitParameters: parameters,
                    }));
                }
            })
            .catch(error => {
                console.error("Error fetching drill bit data:", error);
            });
    }, [selectedItems.well_id, selectedItems.wellbore_id, selectedItems.well_design_case_id, baseURL, holeSize, setSelectedItems]);


    const deleteBit = () => {
        setShowConfirmationModal(false);

        axios.delete(`${baseURL}/input/bit/${drillBitIdToDelete}`, {
            withCredentials: true
        })
            .then(response => {
                // fetchUniqueDrillBitIdAndData();
                axios.get(`${baseURL}/input/bit_id/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`, {
                    withCredentials: true
                })
                    .then(response => {
                        const all_bit_data = response.data;
                        // console.log('bit data:', all_bit_data);

                        setAllBitData(all_bit_data);
                        const parameters = all_bit_data.find(item => item.drill_bit_id === selectedItems.default_bit_id);

                        if (parameters) {
                            // Store the parameters in the global state
                            setSelectedItems(prevState => ({
                                ...prevState,
                                bitParameters: parameters
                            }));
                            // console.log('bit data', parameters);
                        }

                    })
                    .catch(error => {
                        console.error("Error fetching unique drill bit ID:", error);
                    });
            })
            .catch(error => {
                console.error('Error deleting drill bit:', error);
            });

        alert("Successfully Deleted");
    };

    const handleClose = () => setShowAddBitModal(false);

    const handleBitAdded = () => {
        // fetchUniqueDrillBitIdAndData();
        axios.get(`${baseURL}/input/bit_id/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`, {
            withCredentials: true
        })
            .then(response => {
                const all_bit_data = response.data;
                // console.log('bit data:', all_bit_data);

                setAllBitData(all_bit_data);
                const parameters = all_bit_data.find(item => item.drill_bit_id === selectedItems.default_bit_id);

                if (parameters) {
                    // Store the parameters in the global state
                    setSelectedItems(prevState => ({
                        ...prevState,
                        bitParameters: parameters
                    }));
                    // console.log('bit data', parameters);
                }

            })
            .catch(error => {
                console.error("Error fetching unique drill bit ID:", error);
            });
        handleClose();
        setShowAddBitSuccess(true);
        setTimeout(() => setShowAddBitSuccess(false), 3000); // Hide alert after 3 seconds

    };

    const handleEditBit = (bit) => {
        setBitToEdit(bit);
        setShowEditBitModal(true);
    };

    const handleBitUpdated = (updatedBit) => {
        // fetchUniqueDrillBitIdAndData();
        axios.get(`${baseURL}/input/bit_id/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`, {
            withCredentials: true
        })
            .then(response => {
                const all_bit_data = response.data;
                // console.log('bit data:', all_bit_data);

                setAllBitData(all_bit_data);
                const parameters = all_bit_data.find(item => item.drill_bit_id === selectedItems.default_bit_id);

                if (parameters) {
                    // Store the parameters in the global state
                    setSelectedItems(prevState => ({
                        ...prevState,
                        bitParameters: parameters
                    }));
                    // console.log('bit data', parameters);
                }

            })
            .catch(error => {
                console.error("Error fetching unique drill bit ID:", error);
            });

        // Find the updated bit in the allBitData array
        const updatedBitData = allBitData.find(bit => bit.drill_bit_id === updatedBit.drill_bit_id);

        if (updatedBitData) {
            const { bit_manufacturer, bit_size, bit_identification, bit_type } = updatedBitData;
            setSuccessMessage(`You have successfully updated the records of ${bit_manufacturer?.bit_manufacturer || "N/A"} ${bit_size} inch ${bit_type} ${bit_identification} drill bit.`);
            setShowSuccess(true);
        }

        setShowEditBitModal(false);
    };

    const handleDefaultChange = (bitId) => {
        setSelectedItems((prevState) => ({
            ...prevState,
            default_bit_id: bitId
        }));
    };

    // console.log('bit available', selectedItems.default_bit_id);

    return (
        <div>
            <div className="container h-0">
                <div className="row h-20">
                    {showAddBitSuccess && (
                        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                            You have successfully added a new drill bit to the current design case.
                            It should now be available for selection in the Bit table.
                            Please close this dialog box to return to the drill bit table.
                        </Alert>
                    )}
                    {showSuccess && (
                        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                            {successMessage}
                        </Alert>
                    )}
                    <div className="table small">
                        <table className="table text-nowrap ">
                            <thead className="border-top border-primary">
                                <tr>
                                    <th className="fw-normal">Default</th>
                                    <th className="fw-normal">Type</th>
                                    <th className="fw-normal">Make</th>
                                    <th className="fw-normal">ID</th>
                                    <th className="fw-normal">Size</th>
                                    <th className="fw-normal">IADC</th>
                                    <th className="fw-normal">Jet size 1</th>
                                    <th className="fw-normal">No of jets 1</th>
                                    <th className="fw-normal">Jet size 2</th>
                                    <th className="fw-normal">No of jets 2</th>
                                    <th className="fw-normal">TFA</th>
                                    <th className="fw-normal">Total TFA</th>
                                    <th className="fw-normal">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredBits.map((bit, index) => {
                                    const tfa1 = (bit.nozzle_size1 ** 2 / 1303.8) * bit.no_of_nozzles1;
                                    const tfa2 = (bit.nozzle_size2 ** 2 / 1303.8) * bit.no_of_nozzles2;
                                    const totalTfa = tfa1 + tfa2;
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Form.Check
                                                    type="radio"
                                                    name="defaultBit"
                                                    checked={selectedItems.default_bit_id === bit.drill_bit_id}
                                                    onChange={() => handleDefaultChange(bit.drill_bit_id)}
                                                />
                                            </td>
                                            <td>{bit.bit_type}</td>
                                            <td>{bit.bit_manufacturer?.bit_manufacturer || "N/A"}</td>
                                            <td>{bit.bit_identification}</td>
                                            <td>{bit.bit_size}</td>
                                            <td>{bit.iadc_code}</td>
                                            <td>{bit.nozzle_size1}</td>
                                            <td>{bit.no_of_nozzles1}</td>
                                            <td>{bit.nozzle_size2}</td>
                                            <td>{bit.no_of_nozzles2}</td>
                                            <td>{tfa1.toFixed(3)}</td>
                                            <td>{totalTfa.toFixed(3)}</td>
                                            <td>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    className="rounded-circle"
                                                    onClick={() => handleEditBit(bit)}
                                                >
                                                    <i className="bi bi-pencil"></i>
                                                </Button>
                                                <Button
                                                    variant="outline-danger"
                                                    className="rounded-circle"
                                                    size="sm"
                                                    onClick={() => {
                                                        setDrillBitIdToDelete(bit.drill_bit_id);
                                                        setShowConfirmationModal(true);
                                                    }}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        <Button
                            variant="primary"
                            onClick={() => setShowAddBitModal(true)}
                            style={{ marginTop: '20px' }}
                        >
                            Add New Bit
                        </Button>

                        <AddBitForm
                            show={showAddBitModal}
                            handleClose={() => setShowAddBitModal(false)}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onBitAdded={handleBitAdded}
                        />
                    </div>
                </div>
            </div>

            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this bit record?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={deleteBit}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {bitToEdit && (
                <EditBitModal
                    show={showEditBitModal}
                    handleClose={() => setShowEditBitModal(false)}
                    bit={bitToEdit}
                    onBitUpdated={handleBitUpdated}
                />
            )}
        </div>
    );
}