import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

function EditRigEquipmentModal({ show, handleClose, equipment, onEquipmentUpdated }) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    const [formData, setFormData] = useState({
        liner_size: equipment.liner_size,
        stroke_len: equipment.stroke_len,
        pump_spm: equipment.pump_spm,
        pump_eff: equipment.pump_eff
    });

    useEffect(() => {
        if (equipment) {
            setFormData({
                liner_size: equipment.liner_size,
                stroke_len: equipment.stroke_len,
                pump_spm: equipment.pump_spm,
                pump_eff: equipment.pump_eff
            });
        }
    }, [equipment]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const updatedEquipment = {
            liner_size: formData.liner_size,
            stroke_len: formData.stroke_len,
            pump_spm: formData.pump_spm,
            pump_eff: formData.pump_eff
        };

        axios.put(`${baseURL}/input/rig_equipment/${equipment.rig_equip_id}`, updatedEquipment, {
            withCredentials: true
        })
            .then(response => {
                onEquipmentUpdated(response.data.equipment);
                handleClose();
            })
            .catch(error => {
                console.error('Error updating pump:', error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Rig Equipment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="equipmentClass">
                        <Form.Label column sm={4}>Class</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                value={equipment.surf_equip_class}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="pump">
                        <Form.Label column sm={4}>Pump</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                value={equipment.pump_num}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="linerSize">
                        <Form.Label column sm={4}>Liner Size</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.liner_size}
                                onChange={(e) => setFormData({ ...formData, liner_size: e.target.value })}
                                required

                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="strokeLen">
                        <Form.Label column sm={4}>Stroke</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.stroke_len}
                                onChange={(e) => setFormData({ ...formData, stroke_len: e.target.value })}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="pumpSpm">
                        <Form.Label column sm={4}>Pump SPM</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.pump_spm}
                                onChange={(e) => setFormData({ ...formData, pump_spm: e.target.value })}

                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="pumpEff">
                        <Form.Label column sm={4}>Pump Eff</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                value={formData.pump_eff}
                                onChange={(e) => setFormData({ ...formData, pump_eff: e.target.value })}
                                required
                            />
                        </Col>
                    </Form.Group>

                    <Button variant="primary" type="submit" style={{ marginTop: '20px' }}>
                        Save Changes
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditRigEquipmentModal;



