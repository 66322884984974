import React, { useState } from "react";
// import DrillingAndFrictionFactors from "./DrillingAndFrictionFactors";
import { Button } from "react-bootstrap";
import { useSurveyData } from "../../trajectory/SurveyDataProvider";
import BhaElements from "../../utils/bhaElements";
import { useHoleSection } from "../../HoleSectionProvider";
// import { useBhaData } from "../../well_information/bha_items/BhaDataProvider";
import DivideLengthsIntoSections from "../../utils/divideLengthsIntoSections";
import interpolateSurv from "../../utils/interpolateSurv";
import TorqueAndDragChart from "./TorqueAndDragChart";
import BucklingChart from "./BucklingChart";
import TorqueChart from "./TorqueChart";
import { round } from "lodash";
import LegendPositionControl from "../../utils/LegendPositionControl";

const TorqueAndDragCalculator = ({ parameters, frictionFactors, selectedFactors }) => {
    const [legendPosition, setLegendPosition] = useState('bottom');

    // Conversion constants
    const DEG_TO_RAD = Math.PI / 180;
    // const RAD_TO_DEG = 180 / Math.PI;

    const { selectedHoleItems } = useHoleSection();
    // const { bhaData, fullBhaData } = useBhaData();
    const { surveyData } = useSurveyData();

    // Use the data for calculations or rendering
    console.log("Drilling Parameters:", parameters);
    console.log("Friction Factors:", frictionFactors);
    console.log("Selected Activities:", selectedFactors);

    const [results, setResults] = useState([]);
    // const [forces, setForces] = useState([]);

    const mw = parameters.mudWeight;
    console.log('parameters', parameters);
    const blockWeight = parameters.blockWeight;

    console.log('mw', mw);
    console.log('hookLoad', parameters.blockWeight);

    const bhaList = BhaElements();

    const pipeLenList = bhaList[1];
    const pipeOdList = bhaList[2];
    const pipeIdList = bhaList[3];
    const pipeWtList = bhaList[6];

    const holeSize = selectedHoleItems?.openHoleParameters?.hole_size;
    const casingIntDiam = selectedHoleItems.casedHoleParameters.casing_id;
    const casedHoleMd = selectedHoleItems.casedHoleParameters.depth_md;
    console.log('casedHoleMd', casedHoleMd);
    console.log('selectedHoleItems', selectedHoleItems);

    console.log('selectedHoleItems.drillingLiner', selectedHoleItems.drillingLiner);
    console.log('selectedHoleItems.casingAboveDrillingLiner', selectedHoleItems.casingAboveDrillingLiner);

    // depth and ID of previous casing where there is a liner
    const prevCasingIntDiam = selectedHoleItems.drillingLiner? selectedHoleItems.casingAboveDrillingLiner.casing_id : '';
    const prevCasingMd = selectedHoleItems.drillingLiner ? selectedHoleItems.casingAboveDrillingLiner.depth_md : '';
    console.log('prevCasingMd', prevCasingMd);
    console.log('prevCasingIntDiam', prevCasingIntDiam);


    // note: outputs of DivideLengthsIntoSections are sectionLengths, sectionWeights, sectionOds, sectionIds, sectionTjOds
    const { sectionLengths, sectionWeights, sectionOds, sectionIds, sectionTjOds } = DivideLengthsIntoSections(pipeLenList, pipeWtList, pipeOdList, pipeIdList, pipeOdList, mw);

    // Validate input data
    const validateInputTables = () => {
        console.log('holeSections length', selectedHoleItems);
        console.log('bhaList.length', bhaList.length);
        // console.log('fullBhaData', fullBhaData);

        if (!selectedHoleItems || !bhaList.length) {
            alert("Please fill in all input tables.");
            return false;
        }
        return true;
    };

    // Add T&D cases
    const addTndCases = () => {
        const checkedCases = [3, 4]; // Mocked data
        const lenList = checkedCases.length;
        return { checkedCases, lenList };
    };

    // Tripping out forces
    const trippingOutSelected = selectedFactors.trippingOut;
    const trippingInSelected = selectedFactors.trippingIn;
    const rotatingOffBottomSelected = selectedFactors.rotatingOffBottom;
    const rotatingOnBottomSelected = selectedFactors.rotatingOnBottom;
    const slideDrillingSelected = selectedFactors.slideDrilling;
    const backReamingSelected = selectedFactors.backReaming;

    // Main function to calculate forces
    const calculateForces = () => {
        if (!validateInputTables()) return;

        const { checkedCases } = addTndCases();
        const cases = [3, 4, 5];
        const caseColumns = {};

        cases.forEach((c, index) => {
            if (checkedCases.includes(c)) {
                caseColumns[c] = 6 + index + 1;
            }
        });

        console.log('caseColumns', caseColumns);
        const td = selectedHoleItems.openHoleParameters.depth_md;

        // define hole diameter (for both cased and open hole)

        // let bit_depth = parameters.bitDepth;
        let d_depth = 0; // Starting depth
        const openHoleMd = td;

        let prevSurvey = interpolateSurv(surveyData, 0); // Initial survey at the surface
        const resultsTable = [];
        const forcesTable = [];

        let _T1out = - parameters.wob;
        let muTrippingout;
        let muTrippingIn;
        let muRotatingOffBottom;
        let muRotatingOnBottom;
        let muSlideDrilling;
        let muBackReaming;
        let _T1in = 0;
        let bucklingStatus = "";
        let _T1rot_off_bot = 0;
        let _M1 = 0; // used as bit torque for rotating off bottom - check and confirm if this assumption is correct
        let _T = 0; // used as tension at bit for rotating off bottom - check and confirm if this assumption is correct
        let _M1_RotOnBtm = parameters.bitTorque;
        let rpm = parameters.rpm;
        let trip_speed = parameters.rop;
        let _T1slide = -parameters.wob; // is it necessary to have a separate WOB input field for sliding?
        let _T1ream = 0;

        sectionLengths.forEach((length, index) => {
            const sectionLength = sectionLengths[index];
            d_depth += sectionLength;

            // get moment of inertia I, and section modulus E for each element
            const { _E, _I } = calculuateMomentOfInertia(index);

            // Interpolate survey data at the current depth
            const currentDepth = d_depth < openHoleMd ? d_depth : openHoleMd;
            const currentSurvey = interpolateSurv(surveyData, currentDepth);

            // Calculate differences
            const deltaInclination = (currentSurvey.inclination - prevSurvey.inclination) * DEG_TO_RAD;
            const deltaAzimuth = (currentSurvey.azimuth - prevSurvey.azimuth) * DEG_TO_RAD;

            // Calculate average inclination
            const avgInclination = Math.abs(deltaInclination / 2);

            // Calculate average changes
            const avgInclinationChange = deltaInclination / sectionLength;
            const avgAzimuthChange = deltaAzimuth / sectionLength;

            // Calculate magnitudes of changes
            const magInclinationChange = Math.abs(deltaInclination);
            const magAzimuthChange = Math.abs(deltaAzimuth);

            // Interpolate dogleg severity (DLS)
            const topDls = prevSurvey.dls;
            const bottomDls = currentSurvey.dls;

            // Calculate radial clearance
            // if d_depth < prevCasingMd, _Dh = prevCasingIntDiam, else .. 
            
            let _Dh;
            if (prevCasingMd && d_depth <= prevCasingMd) {
                _Dh = prevCasingIntDiam;
            } else if (d_depth <= casedHoleMd) {
                _Dh = casingIntDiam;
            } else {
                _Dh = holeSize;
            }

            const tj_OD = sectionTjOds[index];
            const _OD = sectionOds[index];
            const r = tj_OD === "" ? (_Dh - _OD) / 2 : (_Dh - tj_OD) / 2;
            const _ID = sectionIds[index];

            const _W = sectionWeights[index]

            if (d_depth < casedHoleMd) {
                muTrippingout = trippingOutSelected ? frictionFactors.casedHole.trippingOut : 0;
                muTrippingIn = trippingInSelected ? frictionFactors.casedHole.trippingIn : 0;
                muRotatingOffBottom = rotatingOffBottomSelected ? frictionFactors.casedHole.rotatingOffBottom : 0;
                muRotatingOnBottom = rotatingOnBottomSelected ? frictionFactors.casedHole.rotatingOnBottom : 0;
                muSlideDrilling = slideDrillingSelected ? frictionFactors.casedHole.slideDrilling : 0;
                muBackReaming = backReamingSelected ? frictionFactors.casedHole.backReaming : 0;

            } else {
                muTrippingout = trippingOutSelected ? frictionFactors.openHole.trippingOut : 0;
                muTrippingIn = trippingInSelected ? frictionFactors.openHole.trippingIn : 0;
                muRotatingOffBottom = rotatingOffBottomSelected ? frictionFactors.openHole.rotatingOffBottom : 0;
                muRotatingOnBottom = rotatingOnBottomSelected ? frictionFactors.openHole.rotatingOnBottom : 0;
                muSlideDrilling = slideDrillingSelected ? frictionFactors.openHole.slideDrilling : 0;
                muBackReaming = backReamingSelected ? frictionFactors.openHole.backReaming : 0;

            }

            console.log('_T1out', _T1out);
            // _T2out, tension at top of segment equals tension at bottom of next segment (calculated using drag and pipe wt for that section)
            const { _T2out, _FN_out } = calculateForcesTrippingOut(_T1out, _W, muTrippingout, avgInclination, magAzimuthChange, magInclinationChange);

            // Tripping in forces
            const pi = parameters.spp;
            const po = 0; // check and update this and link to the relevant input parameter
            const wt_per_ft_buoyed = _W / sectionLength;

            const { _T2in, _Fcr, _FN_in } = calculateForcesTippingIn(_T1in, _W, avgInclination, muTrippingIn, _OD, _ID, wt_per_ft_buoyed, _I, _Dh, _E, r, pi, po, magInclinationChange, magAzimuthChange)
            console.log('_FN_in', _FN_in);
            console.log('_Fcr', _Fcr);

            const _Fb = _T2in - _T1in; // buckling force

            const lateral_buckling_margin = round(Math.sqrt(2) * _Fcr, 1);
            const helical_buckling_margin = round(2 * Math.sqrt(2) * _Fcr, 1);

            if (_Fb < _Fcr) {
                bucklingStatus = 'No'
            } else if (_Fcr < _Fb < Math.sqrt(2) * _Fcr) {
                bucklingStatus = 'Lateral'
            } else if (Math.sqrt(2 * _Fcr) < _Fb < 2 * Math.sqrt(2) * _Fcr) {
                bucklingStatus = 'LB or HB'
            } else if (2 * Math.sqrt(2) * _Fcr < _Fb) {
                bucklingStatus = 'Helical'
            } else {
                bucklingStatus = ''
            }

            // rotating off bottom
            const _T2_roffb = round(calculateForcesRotatingOffBottom(_T1rot_off_bot, _W, avgInclination));

            const _Fx = _T * Math.sin(magInclinationChange) + _W * Math.sin(avgInclination);
            const _Fy = _T * Math.sin(magAzimuthChange) * Math.sin(avgInclination);
            const _FN = Math.sqrt(_Fx ** 2 + _Fy ** 2);

            const _R = (_OD / 2) / 12; // this is in feet
            console.log('_R', _R);
            const _M2_RotOffBtm = calculateTorqueRotOffBtm(_M1, muRotatingOffBottom, _FN, _R);

            // rotating on bottom
            const _M2_RotOnBtm = calculateTorqueRotOnBtm(_M1_RotOnBtm, muRotatingOnBottom, _FN, _R, trip_speed, rpm, _OD);

            // sliding
            // const { _T2slide, _Fcr_sliding, _FN_in_Sliding } = calculateForcesSliding(_T1slide, _W, avgInclination, muSlideDrilling, _OD, _ID, wt_per_ft_buoyed, _I, _Dh, _E, r, pi, po, magInclinationChange, magAzimuthChange);
            const slidingForces = calculateForcesSliding(_T1slide, _W, avgInclination, muSlideDrilling, _OD, _ID, wt_per_ft_buoyed, _I, _Dh, _E, r, pi, po, magInclinationChange, magAzimuthChange);

            const _T2slide = round(slidingForces._T2in, 1);
            const _FN_in_Sliding = round(slidingForces._FN_in, 1);
            console.log('_T1slide', _T1slide);
            console.log('_T2slide', _T2slide);
            console.log('_FN_in_Sliding', _FN_in_Sliding);

            console.log('muSlideDrilling', muSlideDrilling);
            console.log('_I', _I);

            // reaming
            const _FxReam = _T1ream * Math.sin(magInclinationChange) + _W * Math.sin(avgInclination)
            const _FyReam = _T1ream * Math.sin(magAzimuthChange) * Math.sin(avgInclination)
            const _FN_ream = Math.sqrt(_FxReam ** 2 + _FyReam ** 2)
            const _T2ream = round(calculateForcesReaming(_T1ream, _W, avgInclination, muBackReaming, _FN_ream, trip_speed, rpm, _OD), 1);

            // Store results
            resultsTable.push({
                d_depth,
                inclinationTop: prevSurvey.inclination,
                inclinationBottom: currentSurvey.inclination,
                azimuthTop: prevSurvey.azimuth,
                azimuthBottom: currentSurvey.azimuth,
                topDls,
                bottomDls,
                avgInclinationChange,
                magInclinationChange,
                avgAzimuthChange,
                magAzimuthChange,
                weight: sectionWeights[index].toFixed(1),
                temporary_Dh: prevSurvey.inclination,
                temporary_r: currentSurvey.inclination,
                puw: _T2out + blockWeight,
                fnOut: _FN_out,
                t1out: _T1out, // axialForceStart
                dFt: _T2out - _T1out,
                t2Out: _T2out, // axialForceEnd
                sow: _T2in + blockWeight,
                fcr: _Fcr,
                fb: _Fb,
                lateral_buckling_margin,
                helical_buckling_margin,
                bucklingStatus,
                rot: _T2_roffb + blockWeight,
                torqueRotOffBtm: _M2_RotOffBtm,
                torqueRotOnBtm: _M2_RotOnBtm,
                // fcrSliding: _Fcr_sliding,
                sliding: _T2slide + blockWeight,
                reaming: _T2ream + blockWeight,
                muTrippingout,

            });

            forcesTable.push({
                d_depth,
                inclination: currentSurvey.inclination,
                azimuth: currentSurvey.azimuth,
                normalForce: calculateNormalForce(),
            });

            // Update the previous survey to the current one
            prevSurvey = currentSurvey;
            _T1out = _T2out;
            _T1in = _T2in;
            _T1rot_off_bot = _T2_roffb;
            _M1 = _M2_RotOffBtm;
            _M1_RotOnBtm = _M2_RotOnBtm;
            _T1slide = _T2slide;
            _T1ream = _T2ream;

        });

        // Set results
        setResults(resultsTable);
        // Uncomment if needed: setForces(forcesTable);

    };

    // Helper functions for calculations

    const calculateNormalForce = () => Math.random() * 1000;
    const calculuateMomentOfInertia = (index) => {
        const _OD = sectionOds[index]
        const _ID = sectionIds[index]
        console.log('_OD', _OD);
        console.log('_ID', _ID);

        // _E = math.pi * (_OD ** 4 - _ID ** 4) / (32 * _OD) // Section modulus
        const _E = 29000000 // Young's Modulus

        const _I = Math.PI * (_OD ** 4 - _ID ** 4) / 64 // Moment of inertia I
        return {_E, _I}
    }
    const calculateForcesTrippingOut = (_T1out, _W, muTrippingout, avgInclinationChange, magAzimuthChange, magInclinationChange) => {
        const _Fx = _T1out * Math.sin(magInclinationChange) + _W * Math.sin(avgInclinationChange);
        const _Fy = _T1out * Math.sin(magAzimuthChange) * Math.sin(avgInclinationChange);
        const _FN_out = Math.sqrt(_Fx ** 2 + _Fy ** 2);
        const _T2out = _T1out + _W * Math.cos(avgInclinationChange) + muTrippingout * _FN_out;

        return {_T2out, _Fx, _Fy, _FN_out}
    }

    const calculateForcesTippingIn = (_T1in, _W, avgInclination, muTrippingIn, _OD, _ID, wt_per_ft_buoyed, _I, _Dh, _E, r, pi, po, magInclinationChange, magAzimuthChange) => {
        // normal force
        const _Fx = _T1in * Math.sin(magInclinationChange) + _W * Math.sin(avgInclination);
        const _Fy = _T1in * Math.sin(magAzimuthChange) * Math.sin(avgInclination);
        const _FN_in = Math.sqrt(_Fx ** 2 + _Fy ** 2);

        console.log('_T1in', _T1in);
        console.log('_FN_in', _FN_in);
        console.log('_Fx', _Fx);
        console.log('_Fy', _Fy);
        console.log('avgInclination', avgInclination);
        console.log('magInclinationChange', magInclinationChange);
        console.log('magAzimuthChange', magAzimuthChange);

        // const _B = 1 - 0.015 * mw;

        const _Ai = (_ID / 2) ** 2;
        const _Ao = (_OD / 2) ** 2;
        let _T2in = _T1in + _W * Math.cos(avgInclination) - muTrippingIn * _FN_in + pi * _Ai - po * _Ao;

        console.log('_Dh', _Dh);
        console.log('_OD', _OD);

        // force required to buckle
        const _Fcr = Math.sqrt((9.82 * 100000 * (_OD ** 4 - _ID ** 4) * wt_per_ft_buoyed * Math.sin(avgInclination)) / (_Dh - _OD));
        console.log('_Fcr', _Fcr);

        // if pipe is buckled, an additional force is applied according to equation below
        if (_Fcr > _T2in) {
            console.log('Bucking predicted');

            const _Ff = _Fcr - _T2in;
            const _WN = r * _Ff ** 2 / (4 * _E * avgInclination); // The average contact force for the helically buckled section.EI = pipe bending stiffness, lbf -in .2 ?
            _T2in = _T2in + _WN;

            console.log('_WN', _WN);

            return { _T2in, _Fcr, _Fx, _Fy, _FN_in }
        }
        console.log('_FN_in', _FN_in);
        console.log('_T2in', _T2in);

        return { _T2in, _Fcr, _FN_in}
    }

    const calculateForcesSliding = (_T1slide, _W, avgInclination, muTrippingIn, _OD, _ID, wt_per_ft_buoyed, _I, _Dh, _E, r, pi, po, magInclinationChange, magAzimuthChange) => {
        // normal force
        const _Fx = _T1slide * Math.sin(magInclinationChange) + _W * Math.sin(avgInclination);
        const _Fy = _T1slide * Math.sin(magAzimuthChange) * Math.sin(avgInclination);
        const _FN_in = Math.sqrt(_Fx ** 2 + _Fy ** 2);

        console.log('_T1slide', _T1slide);
        console.log('_FN_in', _FN_in);
        console.log('_Fx', _Fx);
        console.log('_Fy', _Fy);
        console.log('avgInclination', avgInclination);
        console.log('magInclinationChange', magInclinationChange);
        console.log('magAzimuthChange', magAzimuthChange);

        // const _B = 1 - 0.015 * mw;

        const _Ai = (_ID / 2) ** 2;
        const _Ao = (_OD / 2) ** 2;
        let _T2in = _T1slide + _W * Math.cos(avgInclination) - muTrippingIn * _FN_in + pi * _Ai - po * _Ao;

        console.log('_Dh', _Dh);
        console.log('_OD', _OD);

        // force required to buckle
        const _Fcr = Math.sqrt((9.82 * 100000 * (_OD ** 4 - _ID ** 4) * wt_per_ft_buoyed * Math.sin(avgInclination)) / (_Dh - _OD));
        console.log('_Fcr', _Fcr);

        // if pipe is buckled, an additional force is applied according to equation below
        if (_Fcr > _T2in) {
            console.log('Bucking predicted');

            const _Ff = _Fcr - _T2in;
            const _WN = r * _Ff ** 2 / (4 * _E * avgInclination); // The average contact force for the helically buckled section.EI = pipe bending stiffness, lbf -in .2 ?
            _T2in = _T2in + _WN;

            console.log('_WN', _WN);

            // return { _T2in, _Fcr, _Fx, _Fy, _FN_in }
        }
        console.log('_FN_in', _FN_in);
        console.log('_T2in', _T2in);

        return { _T2in, _Fcr, _FN_in }
    }

    const calculateForcesRotatingOffBottom = (_T1, _W, _Iavg) => {

        const _T2 = _T1 + _W * Math.cos(_Iavg)

        return _T2
    }

    const calculateTorqueRotOffBtm = (_M1, muRotatingOffBottom, _FN, _R) => {
        const _M2 = _M1 + muRotatingOffBottom * _FN * _R;
        console.log('_M1', _M1);
        console.log('muRotatingOffBottom', muRotatingOffBottom);
        console.log('_FN', _FN);

        return _M2
    }

    const calculateTorqueRotOnBtm = (_M1, muRotatingOnBottom, _FN, _R, trip_speed, rpm, _OD) => {
        const ang_Speed = _OD * Math.PI * rpm / 60; // angular speed, in/sec
        const _V = Math.sqrt(trip_speed ** 2 + ang_Speed ** 2); // resultant speed
        const _M2 = _M1 + muRotatingOnBottom * _FN * _R * Math.abs(trip_speed / _V);
        return _M2
    }

    const calculateForcesReaming = (_T1ream, _W, avgInclination, muBackReaming, _FN_ream, trip_speed, rpm, _OD) => {
        const ang_speed = _OD * Math.PI * rpm / 60; // angular speed, in/sec
        console.log('ang_speed', ang_speed);

        const _V = Math.sqrt(trip_speed ** 2 + ang_speed ** 2); // resultant speed
        console.log('_V reaming', _V);

        const _T2ream = _T1ream + _W * Math.cos(avgInclination) + muBackReaming * _FN_ream * Math.abs(trip_speed / _V);

        return _T2ream
    }

    return (
        <div className="">
            <div className="mt-3">
                <Button onClick={calculateForces}>Calculate Forces</Button>
            </div>
            <div className="mt-3">
                {/* <b>Chart Controls</b> */}
                <LegendPositionControl onChange={setLegendPosition} />

            </div>
            <div className="bg-light">
                <TorqueAndDragChart legendPosition={legendPosition} results={results} parameters={parameters} frictionFactors={frictionFactors} selectedFactors={selectedFactors} />
            </div>
            <div className="mt-3 bg-light">
                <BucklingChart legendPosition={legendPosition} results={results} />
            </div>
            <div className="mt-3 bg-light">
                <TorqueChart legendPosition={legendPosition} results={results} parameters={parameters} frictionFactors={frictionFactors} selectedFactors={selectedFactors} />
            </div>
            {/* Results */}
            <h5 className="border-bottom mt-3">Results</h5>
            <div className="container table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Depth</th>
                            <th>Inc</th>
                            <th>Azi</th>
                            <th>SOW</th>
                            <th>PUW</th>
                            <th>ROT</th>
                            {slideDrillingSelected && <th>Sliding</th>}
                            {backReamingSelected && <th>Reaming</th>}
                            <th>M<sub>offBtm</sub></th>
                            {rotatingOnBottomSelected && <th>M<sub>onBtm</sub></th>}
                            <th>W<sub>b</sub></th>
                            <th>F<sub>t</sub></th>
                            <th>F<sub>N</sub></th>
                            <th>&Delta;Ft</th>
                            <th>Ft+&Delta;Ft</th>
                            <th>F<sub>b</sub></th>
                            <th>F<sub>cr</sub></th>
                            <th>F<sub>hb</sub></th>
                            <th>F<sub>sb</sub></th>
                            <th>Buckling</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((row, index) => (
                            <tr key={index}>
                                <td>{row.d_depth}</td>
                                <td>{row.inclinationBottom.toFixed(2)}</td>
                                <td>{row.azimuthBottom.toFixed(2)}</td>
                                <td>{row.sow.toFixed(1)}</td>
                                <td>{row.puw.toFixed(1)}</td>
                                <td>{row.rot.toFixed(1)}</td>
                                {slideDrillingSelected && <td>{row.sliding}</td>}
                                {backReamingSelected && <td>{row.reaming}</td>}
                                <td>{row.torqueRotOffBtm.toFixed(1)}</td>
                                {rotatingOnBottomSelected && <td>{row.torqueRotOnBtm.toFixed(1)}</td>}
                                <td>{row.weight}</td>
                                <td>{row.t1out.toFixed(1)}</td>
                                <td>{row.fnOut.toFixed(1)}</td>
                                <td>{row.dFt.toFixed(1)}</td>
                                <td>{row.t2Out.toFixed(1)}</td>
                                <td>{row.fb.toFixed(2)}</td>
                                <td>{row.fcr?.toFixed(2)}</td>
                                <td>{row.helical_buckling_margin}</td>
                                <td>{row.lateral_buckling_margin}</td>
                                <td>{row.bucklingStatus}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default TorqueAndDragCalculator;
