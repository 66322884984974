import React, { createContext, useContext, useState, useCallback } from "react";
import axios from "axios";
import { GlobalStateContext } from "../../GlobalStateContext";

const RheologyDataContext = createContext();

export function useRheologyData() {
    return useContext(RheologyDataContext);
}

export function RheologyDataProvider({ children, baseURL }) {
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);

    const [holeSection, setHoleSection] = useState([]);
    const [sectionTypeMap, setSectionTypeMap] = useState({});

    const [nextHoleSectionRheol, setNextHoleSectionRheol] = useState([]);
    const [prevHoleSectionRheol, setPrevHoleSectionRheol] = useState({});

    const fetchRheologyDatabyWellWellbore = useCallback(() => {
        axios
            .get(
                `${baseURL}/input/rheology/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`,
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                const rheologyData = response.data;
                setHoleSection(rheologyData);

                setSelectedItems((prevRheol) => ({
                    ...prevRheol,
                    rheologicalParameters: rheologyData,
                }));

                const currentIndex = rheologyData.findIndex(
                    (item) => item.section_type_id === selectedItems.hole_default_parameter_id
                );
                const nextHoleSection = rheologyData[currentIndex + 1];
                const previousHoleSection = rheologyData[currentIndex - 1];

                if (nextHoleSection) {

                    setNextHoleSectionRheol(nextHoleSection);
                    setPrevHoleSectionRheol(previousHoleSection);

                } else {
                    console.log("No next hole section found.");
                }
            })
            .catch((error) => {
                console.error("Error fetching rheology data:", error);
            });
    }, [baseURL, selectedItems.hole_default_parameter_id, selectedItems.well_design_case_id, selectedItems.well_id, selectedItems.wellbore_id, setSelectedItems]);

    const fetchHoleSectionTypebySectionTypeId = useCallback((sectionTypeId) => {
        axios
            .get(`${baseURL}/input/section_type/${sectionTypeId}`, {
                withCredentials: true,
            })
            .then((response) => {
                const sectionType = response.data;
                setSectionTypeMap((prevState) => ({
                    ...prevState,
                    [sectionTypeId]: sectionType,
                }));
            })
            .catch((error) => {
                console.error("Error fetching hole section type:", error);
            });
    },[baseURL]);

    return (
        <RheologyDataContext.Provider
            value={{
                holeSection,
                sectionTypeMap,
                nextHoleSectionRheol,
                prevHoleSectionRheol,
                fetchRheologyDatabyWellWellbore,
                fetchHoleSectionTypebySectionTypeId,
            }}
        >
            {children}
        </RheologyDataContext.Provider>
    );
}
