import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function SignupConfirmation() {
    const location = useLocation();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const messageParam = queryParams.get('message');

        switch (messageParam) {
            case 'invalid_or_expired':
                setMessage('The confirmation link is invalid or has expired. Please try again.');
                break;
            case 'already_confirmed':
                setMessage('Account already confirmed. Please login.');
                break;
            case 'confirmed':
                setMessage('You have confirmed your account. Thanks! Please log into your account.');
                if (process.env.NODE_ENV === 'development') {
                    console.log('You have confirmed your account. Thanks! Please log into your account.');
                }
                break;
            default:
                setMessage('Welcome! Please check your email for confirmation details.');
        }
    }, [location.search]);

    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <p className="mb-4">{message || "Processing your confirmation status..."}</p>
                <Button variant="primary" as={Link} to="/login">Login</Button>
            </div>
        </div>
    );
}
