import React from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, LinearScale, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, LinearScale, CategoryScale);

const BucklingChart = ({ legendPosition, results }) => {
    // Prepare data for the chart
    const depthData = results.map((row) => row.d_depth);
    const inclinationData = results.map((row) => row.inclinationBottom);
    const fbData = results.map((row) => row.fb);
    const fcrData = results.map((row) => row.fcr);
    const helicalData = results.map((row) => row.helical_buckling_margin);
    const sinusoidalData = results.map((row) => row.lateral_buckling_margin);
    // const reamData = results.map((row) => row.reaming);

    const chartData = {
        datasets: [
            {
                label: "Inclination",
                data: depthData.map((depth, i) => ({
                    x: inclinationData[i],
                    y: depth,
                })),
                borderColor: "blue",
                backgroundColor: "blue",
                showLine: true, // Adds the connecting line
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: "Buckling force",
                data: depthData.map((depth, i) => ({
                    x: fbData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "green",
                backgroundColor: "green",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: "Critical buckling force",
                data: depthData.map((depth, i) => ({
                    x: fcrData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "red",
                backgroundColor: "red",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: "Helical buckling limit",
                data: depthData.map((depth, i) => ({
                    x: helicalData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "indigo",
                backgroundColor: "indigo",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
            {
                label: "Sinusoidal buckling limit",
                data: depthData.map((depth, i) => ({
                    x: sinusoidalData[i] / 1000, // Kips
                    y: depth,
                })),
                borderColor: "violet",
                backgroundColor: "violet",
                showLine: true,
                tension: 0.3,
                pointStyle: false,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: legendPosition,
                labels: {
                    boxWidth: 10, // Adjust the width of the legend box
                    boxHeight: 3, // Adjust the height of the legend box
                    padding: 10, // Optional: Adjust spacing around legend items
                },

            },
            title: {
                display: true,
                text: "Inclination, Buckling Forces vs. Depth",
            },
            datalabels: false,
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Inclination, Buckling force",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Depth",
                },
                reverse: true, // Reverse depth axis
            },
        },
    };

    return (
        <div style={{ height: "500px" }}>
            <Scatter data={chartData} options={chartOptions} />
        </div>
    );
};

export default BucklingChart;
