import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { GlobalStateContext } from './GlobalStateContext';
import interpolateSurv from './utils/interpolateSurv';
import { useSurveyData } from './trajectory/SurveyDataProvider';
import { isEqual } from 'lodash';
import { round } from "mathjs";
// import { useAuth } from './admin/AuthContext';
import { ModulesContext } from './admin/ModulesContext';

// Create the context
const HoleSectionContext = createContext();

// Create a provider component
export const HoleSectionProvider = ({ children, baseURL, token }) => {
    const { selectedItems } = useContext(GlobalStateContext);
    const [selectedHoleItems, setSelectedHoleItems] = useState({
        openHoleParameters: null,
        casedHoleParameters: null,
        drillingLiner: {},
        casingAboveDrillingLiner: null,
        indexOfDrillingLiner: 0,
        indexOfCasingAboveDrillingLiner: 0,
        indexOfSelectedSection: 0,
        openHoleTvd: 0,
        casedHoleTvd: 0,
        prevCasingTvd: 0,

        // Other initial values...
    });

    // const {
    //     // isAuthenticated,
    //     isTwoFactorVerified,
    // } = useAuth();
    // const activeModules = selectedItems?.active_modules || [];
    // const anyModule = ["Hydraulics", "Torque and Drag", "Kick Tolerance", "Casing Design", "Swab and Surge", "Trajectory Design"].some(module => activeModules.includes(module));

    const { activeModules } = useContext(ModulesContext);
    const anyModule = activeModules.length > 0;

    const { surveyData } = useSurveyData();

    const [holeSection, setHoleSection] = useState([]);
    const [sectionTypeMap, setSectionTypeMap] = useState({});
    const [casingSize, setCasingSize] = useState(null);
    const [casingWt, setCasingWt] = useState(null);
    const [casingGrade, setCasingGrade] = useState(null);
    // const [casingId, setCasingId] = useState(null);

    const fetchCasingData = useCallback(async (casingSize, casingWt, casingGrade) => {
        // if (!casingSize || !casingWt || !casingGrade || !anyModule) {
        //     console.warn("Insufficient parameters or modules not active.");
        //     return null;
        // }

        try {
            const { data } = await axios.get(`${baseURL}/input/casing`, {
                params: {
                    casing_size: casingSize,
                    casing_wt: casingWt,
                    casing_grade: casingGrade,
                },
                withCredentials: true,
            });

            console.log("Fetched casing data:", data);

            return {
                casingId: data?.casing_id ?? null,
                internal_yield_pe: data?.internal_yield_pe ?? null,
                collapse: data?.collapse ?? null,
                body_yield: data?.body_yield ?? null,
            };
        } catch (error) {
            console.error("Error fetching casing data:", error);
            return null;
        }
    }, [baseURL, anyModule]);

    const fetchHoleSectionDatabyWellWellbore = useCallback(async () => {
        try {
            //protection
            if (!anyModule) {
                return;
            }
            console.log("well id:", selectedItems.well_id);
            console.log("wellbore id:", selectedItems.wellbore_id);

            const response = await axios.get(`${baseURL}/input/hole_section/${selectedItems.well_id}/${selectedItems.wellbore_id}`, {
                withCredentials: true,
            });

            let holeData = response.data;
            console.log("Fetched Hole Section Data:", holeData); // Add this
            holeData = holeData?.sort((a, b) => a.depth_md - b.depth_md);
            setHoleSection(holeData);
            console.log('holeData', holeData);

            let drillingLiner = null;
            let casingAboveDrillingLiner = null;
            let indexOfDrillingLiner = 0;
            let indexOfCasingAboveDrillingLiner = 0;
            let prevCasingData = {};

            for (let i = 0; i < holeData.length; i++) {
                const section = holeData[i];
                if (section.section_type_id === 7) {
                    drillingLiner = section;
                    indexOfDrillingLiner = i;
                    if (i > 0) {
                        const previousSection = holeData[i - 1];
                        if (previousSection.section_type_id < 7) {
                            casingAboveDrillingLiner = previousSection;
                            indexOfCasingAboveDrillingLiner = i - 1;
                            break;
                        }
                    }
                }
            }

            if (selectedItems.openHoleParameters && selectedItems.casedHoleParameters) {
                const selectedSection = holeData.find(item => item.section_type_id === selectedItems.hole_default_parameter_id);
                const casedHoleParameters = holeData.find(item => item.section_type_id === selectedItems.casing_default_parameter_id);
                // const casingAboveDrillingLinerParameters = holeData.find(item => item.section_type_id === previousSection.section_type_id);
                const indexOfSelectedSection = holeData.findIndex(item => item.section_type_id === selectedItems.hole_default_parameter_id);

                if (selectedSection && casedHoleParameters) {
                    // Fetch casing data before setting the state
                    const casingData = await fetchCasingData(casedHoleParameters.casing_size, casedHoleParameters.casing_wt, casedHoleParameters.casing_grade);
                    if (drillingLiner) {
                        prevCasingData = await fetchCasingData(casingAboveDrillingLiner.casing_size, casingAboveDrillingLiner.casing_wt, casingAboveDrillingLiner.casing_grade);

                    }

                    console.log('casingData', casingData);
                    console.log('casingData.casingId', casingData.casingId);

                    setSelectedHoleItems(prevState => ({
                        ...prevState,
                        openHoleParameters: selectedSection,
                        casedHoleParameters: {
                            ...casedHoleParameters,
                            casing_id: casingData?.casingId,
                            internal_yield_pe: casingData?.internal_yield_pe,
                            collapse: casingData?.collapse,
                            body_yield: casingData?.body_yield
                        },
                        drillingLiner: {
                            ...drillingLiner,
                            casing_id: casingData?.casingId,
                            internal_yield_pe: casingData?.internal_yield_pe,
                            collapse: casingData?.collapse,
                            body_yield: casingData?.body_yield
                        },
                        casingAboveDrillingLiner: {
                            ...casingAboveDrillingLiner,
                            casing_id: prevCasingData?.casingId,
                            internal_yield_pe: prevCasingData?.internal_yield_pe,
                            collapse: prevCasingData?.collapse,
                            body_yield: prevCasingData?.body_yield
                        },
                        indexOfDrillingLiner,
                        indexOfCasingAboveDrillingLiner,
                        indexOfSelectedSection,
                    }));

                    // Set the casing size, weight, and grade if needed for further updates or UI display
                    setCasingSize(casedHoleParameters.casing_size);
                    setCasingWt(casedHoleParameters.casing_wt);
                    setCasingGrade(casedHoleParameters.casing_grade);

                    // calculateAndSetTVDs(holeData, selectedSection, casedHoleParameters);
                    if (surveyData.length > 0 && holeSection.length > 0) {
                        calculateAndSetTVDs(holeData, selectedSection, casedHoleParameters);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching hole sections:", error);
        }
    }, [selectedItems, baseURL, fetchCasingData]);


    useEffect(() => {
        fetchHoleSectionDatabyWellWellbore();
    }, [fetchHoleSectionDatabyWellWellbore]);

    const fetchHoleSectionTypebySectionTypeId = useCallback((sectionTypeId) => {
        axios.get(`${baseURL}/input/section_type/${sectionTypeId}`, {
            withCredentials: true
        })
            .then((response) => {
                const sectionType = response.data;
                // console.log('sectionType:', sectionType);
                setSectionTypeMap(prevState => ({
                    ...prevState,
                    [sectionTypeId]: sectionType
                }));
            })
            .catch((error) => {
                console.error("Error fetching hole section type:", error);
            });
    }, [baseURL]);

    useEffect(() => {
        if (holeSection && holeSection.length > 0) {
            holeSection.forEach(holeSectionItem => {
                fetchHoleSectionTypebySectionTypeId(holeSectionItem.section_type_id);
            });
        }
    }, [holeSection, fetchHoleSectionTypebySectionTypeId]);

    const prevCasingSize = selectedHoleItems?.casingAboveDrillingLiner?.casing_size;
    const prevCasingWt = selectedHoleItems?.casingAboveDrillingLiner?.casing_wt;
    const prevCasingGrade = selectedHoleItems?.casingAboveDrillingLiner?.casing_grade;
    const prevCasingMd = selectedHoleItems?.casingAboveDrillingLiner?.depth_md;
    console.log('prevCasingMd', prevCasingMd);
    console.log('selectedHoleItems?.casingAboveDrillingLiner', selectedHoleItems?.casingAboveDrillingLiner);

    // console.log("selectedItems.casingAboveDrillingLiner:", selectedHoleItems.casingAboveDrillingLiner); // Add this

    useEffect(() => {
        const fetchCasingIdAndUpdate = async () => {
            if (casingSize && casingWt && casingGrade) {
                try {
                    const casingData = await fetchCasingData(casingSize, casingWt, casingGrade);
                    const casingId = casingData ? casingData.casingId : null;
                    const internalYield = casingData ? casingData.internal_yield_pe : null;
                    const collapse = casingData ? casingData.collapse : null;
                    const bodyYield = casingData ? casingData.body_yield : null;

                    // console.log('casingData----11', response.data)

                    setSelectedHoleItems(prevState => ({
                        ...prevState,
                        casedHoleParameters: {
                            ...prevState.casedHoleParameters,
                            casing_id: casingId,
                            internal_yield_pe: internalYield,
                            collapse: collapse,
                            body_yield: bodyYield
                        },
                    }));

                } catch (error) {
                    console.error("Error fetching casing data:", error);
                }
            }
        };

        fetchCasingIdAndUpdate();
    }, [casingSize, casingWt, casingGrade, fetchCasingData]);

    useEffect(() => {
        // console.log("prevCasingSize:", prevCasingSize); // Add this
        // console.log("prevCasingWt:", prevCasingWt); // Add this
        // console.log("prevCasingGrade:", prevCasingGrade); // Add this

        const fetchPrevCasingIdAndUpdate = async () => {
            if (prevCasingSize && prevCasingWt && prevCasingGrade) {
                try {
                    const prevCasingData = await fetchCasingData(prevCasingSize, prevCasingWt, prevCasingGrade);

                    console.log("Previous Casing Data:", prevCasingData);

                    const prevCasingId = prevCasingData?.casingId ?? null;
                    const prevInternalYield = prevCasingData?.internal_yield_pe ?? null;
                    const prevCollapse = prevCasingData?.collapse ?? null;
                    const prevBodyYield = prevCasingData?.body_yield ?? null;

                    setSelectedHoleItems((prevState) => ({
                        ...prevState,
                        casingAboveDrillingLiner: {
                            ...prevState.casingAboveDrillingLiner, // Safely spread existing properties
                            casing_id: prevCasingId,
                            internal_yield_pe: prevInternalYield,
                            collapse: prevCollapse,
                            body_yield: prevBodyYield,
                            prevCasingMd: prevCasingMd,
                        },
                    }));

                    console.log("prevCasingId:", prevCasingId); // Add this

                } catch (error) {
                    console.error("Error fetching previous casing data:", error);
                }
            }
        };

        fetchPrevCasingIdAndUpdate();
    }, [prevCasingSize, prevCasingWt, prevCasingGrade, fetchCasingData]);

    const calculateTVDs = useCallback(() => {
        const tvds = holeSection.map(item => {

            // Assuming interpolateSurv returns an object with multiple properties
            const result = interpolateSurv(surveyData, item.depth_md);
            const tvd = round(result.tvd, 1); // Extract the tvd property

            // Only update if the TVD is actually different to avoid unnecessary state updates
            if (item.tvd !== tvd) {
                return { ...item, tvd };
            }
            return item;
        });

        // Only update if tvds is different from the current holeSection
        if (!isEqual(tvds, holeSection)) {
            setHoleSection(tvds); // Now 'tvds' is used here
        }

        // TVDs are unchanged, skipping state update

    }, [holeSection, setHoleSection, surveyData]); // Dependencies for useCallback

    // Calculate TVDs using survey data
    const calculateAndSetTVDs = (holeSection, openHoleSection, casedHoleSection) => {
        const openHoleTvd = openHoleSection
            ? round(interpolateSurv(surveyData, openHoleSection.depth_md).tvd, 1)
            : 0;
        const casedHoleTvd = casedHoleSection
            ? round(interpolateSurv(surveyData, casedHoleSection.depth_md).tvd, 1)
            : 0;
        // const prevCasingTvd = casedHoleSection
        //     ? round(interpolateSurv(surveyData, prevCasingMd).tvd, 1)
        //     : 0;
        
        setSelectedHoleItems(prevState => ({
            ...prevState,
            openHoleTvd,
            casedHoleTvd,
            // prevCasingTvd
        }));
    };

    useEffect(() => {
        if (surveyData.length > 0 && holeSection.length > 0) {
            const selectedSection = holeSection.find(item => item.section_type_id === selectedItems.hole_default_parameter_id);
            const casedHoleParameters = holeSection.find(item => item.section_type_id === selectedItems.casing_default_parameter_id);
            calculateAndSetTVDs(holeSection, selectedSection, casedHoleParameters);
            calculateTVDs();
        }
    }, [surveyData, holeSection, selectedItems.hole_default_parameter_id, selectedItems.casing_default_parameter_id]);

    return (
        <HoleSectionContext.Provider value={{ holeSection, setHoleSection, sectionTypeMap, setSectionTypeMap, fetchHoleSectionDatabyWellWellbore, selectedHoleItems, setSelectedHoleItems, fetchHoleSectionTypebySectionTypeId }}>
            {children}
        </HoleSectionContext.Provider>
    );
};

// Custom hook to use the HoleSectionContext
export const useHoleSection = () => useContext(HoleSectionContext);
