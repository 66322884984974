import React, { useContext } from "react";
import LeftSidebar from "../../pages/tabs_components/LeftSidebar";
import Breadcrumbs from "../../pages/tabs_components/Breadcrumb";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ModulesContext } from "./ModulesContext";

const WelcomeRegisteredUser = ({ isTwoFactorVerified }) => {
    const { activeModules, loading, error, availablePaidTools, fullName, freeTools } = useContext(ModulesContext);

    if (loading) return <p>Loading modules...</p>;
    if (error) return <p className="text-danger">Error: {error}</p>;

    if (!isTwoFactorVerified) {
        return (
            <div>
                You are not logged in! Please{" "}
                <Link to="/login" style={{ display: "inline" }}>login to see the contents of this page</Link>.
            </div>
        );
    }

    return (
        <>
            <Breadcrumbs />
            <Container className="min-vh-100">
                <Row>
                    {/* Left Sidebar */}
                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <LeftSidebar />
                    </Col>

                    {/* Main Content Area */}
                    <Col xs={12} md={5} className="mt-5">
                        <div>
                            <h6 className="mb-3">Welcome back, {fullName}</h6>
                            {!fullName && (
                                <p className="text-danger">
                                    Please update your{" "}
                                    <Link to="/my_account" style={{ display: "inline" }}>profile</Link> to include your name and affiliation.
                                </p>
                            )}

                            {activeModules.length > 0 ? (
                                <>
                                    <div>
                                        <span>You have the following active subscriptions:</span>
                                        <ul>
                                            {activeModules.map((module, index) => (
                                                <li key={index}>{module}</li>
                                            ))}
                                        </ul>
                                    </div>

                                    <Link to="/design_case">
                                        Get started: click here to set up a design case.
                                    </Link>
                                    {activeModules.length < 6 && (
                                        <div className="mt-3">
                                            <Button as={Link} to="/subscribe" variant="outline-primary">
                                                Subscribe to the remaining modules
                                            </Button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div>
                                    <span className="text-danger">
                                        You don't have any active well design{" "}
                                        <Link to="/subscribe" style={{ display: "inline" }}>modules</Link>.
                                    </span>
                                    <Link to="/subscribe">
                                        Please subscribe to at least one module to get started with design modules.
                                    </Link>
                                </div>
                            )}
                        </div>
                    </Col>

                    {/* Cards Column */}
                    <Col xs={12} md={4} className="mt-5">
                        {activeModules.length > 0 && (
                            <div>
                                <span>
                                    The following <b>premium tools</b> come with your subscriptions (look in the Tools dropdown in the main menu):
                                </span>
                                <ul>
                                    {availablePaidTools.map((tool, index) => (
                                        <li key={index}>{tool}</li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        <div className="mt-3">
                            <span>
                                You can use the following <b>free tools</b> (look in the Tools dropdown in the main menu):
                            </span>
                            <ul>
                                {freeTools.map((tool, index) => (
                                    <li key={index}>{tool}</li>
                                ))}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default WelcomeRegisteredUser;
