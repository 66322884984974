// AppContent.js
// This is a separate component that accesses useAuth and then passes its values down as props.
import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from './components/admin/AuthContext';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import LandingPage from "./pages/LandingPage";
import RegisterPage from './pages/RegisterPage';
import PrivatePage from './pages/PrivatePage';
import AboutPage from './pages/AboutPage';
import Login from './components/admin/Login';
import Header from './pages/tabs_components/Header';
import Profile from './components/Profile';
import NotFound from './pages/NotFound';
import UnitConverterPage from './pages/UnitConverterPage';
import SurveyInterpolationPage from './pages/SurveyInterpolationPage';
import FluidCompressibilityPage from './pages/FluidCompressibilityPage';
import CasingDimensionsPage from './pages/CasingDimensionsPage';
import CapacityCalculationPage from './pages/CapacityCalculationPage';
import SessionManager from './SessionManager';
import HeaderPage from './pages/DesignCasePage';
import WellInformationPage from './pages/WellInformationPage';
import BhaTable from './components/well_information/bha_items/bhaTable';
import RheologyTable from './components/well_information/rheology/rheologyTable';
import PricingPage from './pages/PricingPage';
import ConfirmEmail from './components/admin/ConfirmEmail';
import SignupFeeback from './components/admin/SignupFeedback';
import SignupConfirmation from './components/admin/SignupConfirmation';
import PasswordResetForm from './components/admin/PasswordResetForm';
import TwoFactorSettings from './components/admin/TwoFactorSettings';
import TwoFactorVerification from './components/admin/TwoFactorVerification';
import TermsOfUse from './components/legal/TermsOfUse';
import PrivacyPolicy from './components/legal/PrivacyPolicy';
import CookiesPage from './components/legal/CookiesPage';
import GasMigrationRateCalculator from './components/engineering_tools/GasMigrationRateCalculator';
import DrillPipeLookup from './components/engineering_tools/DrillPipeLookup';
import PipeStretchAndCollapse from './components/engineering_tools/PipeStretchAndCollapse';
import CasingWearCalculator from './components/engineering_tools/CasingWearCalculator';
import ModulesDashboard from './components/admin/ModulesDashboard';
import SubscriptionSuccess from './components/admin/SubscriptionSuccess';
import AnnularPressureLossForm from './components/engineering_tools/PressureLossForm';
import TemperatureCalculation from './components/engineering_tools/TemperatureCalculation';
import TrajectoryDesignPage from './pages/TrajectoryDesignPage';
import TorqueAndDragPage from './pages/TorqueAndDragPage';
import { ModulesContext } from './components/admin/ModulesContext';

import CompletePage from './components/admin/CompletePage';
// import CheckoutForm from './components/admin/CheckoutForm';

import WelcomeRegisteredUser from './components/admin/WelcomeRegisteredUser';

import AdminPanel from './admin';

// Lazy load components to improve performance
const HydraulicsPage = React.lazy(() => import('./pages/HydraulicsPage'));
const SwabAndSurgePage = React.lazy(() => import('./pages/SwabAndSurgePage'));
const KickTolerancePage = React.lazy(() => import('./pages/KickTolerancePage'));
const CasingDesignPage = React.lazy(() => import('./pages/CasingDesignPage'));

const AppContent = ({ dpmCheckerLink, clientSecret }) => {
    const {
        isAuthenticated,
        setIsAuthenticated,
        isTwoFactorEnabled,
        setIsTwoFactorEnabled,
        isTwoFactorVerified,
        setIsTwoFactorVerified,
        isAdmin,
        setIsAdmin
    } = useAuth();

    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    // const [isAdminGlobal, setIsAdminGlobal] = useState(false);
    // const activeModules = selectedItems?.active_modules || [];
    const { activeModules } = useContext(ModulesContext);
    const location = useLocation();

    useEffect(() => {
        const verifyAuth = async () => {
            try {
                const response = await axios.get(`${baseURL}/verify_auth`, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${document.cookie.replace(/(?:(?:^|.*;\s*)session_id\s*=\s*([^;]*).*$)|^.*$/, "$1")}`,
                        // Authorization: `Bearer ${session_id}`, // Include session_id if stored

                    },
                });

                const {
                    authenticated,
                    user_id,
                    two_factor_enabled,
                    email,
                    full_name,
                    is_admin,
                    is_two_factor_verified,
                } = response.data;

                setIsAuthenticated(authenticated);

                if (authenticated) {
                    setUserId(user_id);
                    setIsTwoFactorEnabled(two_factor_enabled);
                    setEmail(email);
                    setFullName(full_name);
                    setIsAdmin(is_admin);
                    setIsTwoFactorVerified(
                        two_factor_enabled ? is_two_factor_verified || false : true
                    );
                } else {
                    setIsTwoFactorVerified((prevState) => prevState && true);
                }
            } catch (error) {
                console.error("Auth verification failed:", error);
                setIsAuthenticated(false);
                setIsTwoFactorVerified((prevState) => prevState && true);
            } finally {
                setLoading(false);
            }
        };

        verifyAuth();
    }, [
        baseURL,
        setIsAuthenticated,
        setIsTwoFactorEnabled,
        setIsTwoFactorVerified,
        setUserId,
        setEmail,
        setFullName,
        setIsAdmin,
    ]);

    const ProtectedModuleRoute = ({ moduleName, children }) => {
        if (activeModules.includes(moduleName)) {
            return children;
        }
        return <Navigate to="/subscribe" />;
    };

    const anyModule = ["Hydraulics", "Torque and Drag", "Kick Tolerance", "Casing Design", "Swab and Surge", "Trajectory Design"].some(module => activeModules.includes(module));

    if (loading) {
        return <div>Loading...</div>; // Loading screen while verifying authentication status
    }

    // console.log('isAuthenticated', isAuthenticated)
    // console.log('isTwoFactorVerified', isTwoFactorVerified)
    // console.log('isTwoFactorEnabled', isTwoFactorEnabled)
    // console.log('userId', userId)
    // console.log('isAdmin?')
    // console.log('isAdmin---', isAdmin)

    return (
        <>
            {/* Render Header only when not on an /admin route */}
            {!location.pathname.startsWith('/admin') && (
                <Header
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                    isTwoFactorVerified={isTwoFactorVerified}
                    setIsTwoFactorVerified={setIsTwoFactorVerified}
                    email={email} 
                    fullName={fullName}
                    isAdmin={isAdmin}
                    setIsTwoFactorEnabled={setIsTwoFactorEnabled}
                    setEmail={setEmail}
                    userId={userId}
                    setUserId={setUserId}
                    setFullName={setFullName}
                />
            )}
            {isAuthenticated && <SessionManager />}

                                        <Routes>
                                            {/* Public Routes */}
                                            <Route path="/complete" element={<CompletePage />} />
                                            <Route path="/" element={<LandingPage />} />
                                            <Route path="/about" element={<AboutPage />} />
                                            <Route path="/pricing" element={<PricingPage />} />
                                            <Route path="/register" element={<RegisterPage isTwoFactorVerified={isTwoFactorVerified} />} />
                                            <Route path="/signup_feedback" element={<SignupFeeback />} />
                                            <Route path="/signup_confirmation" element={<SignupConfirmation />} />
                                            <Route path="/reset_password/:token" element={<PasswordResetForm />} />
                                            <Route path="/confirm/:token" element={<ConfirmEmail />} />
                                            <Route path="/terms-of-use" element={<TermsOfUse />} />
                                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                            <Route path="/cookies" element={<CookiesPage />} />
                                            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} setIsTwoFactorVerified={setIsTwoFactorVerified} />} />

                                            {/* 2FA Verification Route */}
                                            <Route
                                                path="/2fa_verification"
                                                element={
                                                    isAuthenticated && isTwoFactorEnabled && !isTwoFactorVerified ? (
                                                        <TwoFactorVerification user_id={userId} setIsTwoFactorVerified={setIsTwoFactorVerified} />
                                                    ) : (
                                                        <Navigate to="/" />
                                                    )
                                                }
                                            />

                                            {/* Protected Routes */}
                                            {isAuthenticated && (isTwoFactorVerified || !isTwoFactorEnabled) && userId ? (
                                                <>
                                                    <Route
                                                        path="/welcome"
                                                        element={
                                                            isTwoFactorVerified ? (
                                                                <WelcomeRegisteredUser isTwoFactorVerified={isTwoFactorVerified} userId={userId} />
                                                            ) : (
                                                                <Navigate to="/" />
                                                            )
                                                        }
                                                    />
                                                    
                        <Route path="/design_case"
                            element={
                                anyModule ? (
                                    <HeaderPage isTwoFactorVerified={isTwoFactorVerified} userId={userId} />

                                ) : (
                                        <Navigate to="/subscribe" />
                                )
                            }
                        />
                        <Route path="/well_information" element={<WellInformationPage />} />

                                                    <Route path="/subscribe" element={<ModulesDashboard isTwoFactorVerified={isTwoFactorVerified} userId={userId} />} />
                                                    <Route path="/units" element={<UnitConverterPage />} />
                                                    <Route path="/interpolation" element={<SurveyInterpolationPage />} />
                                                    <Route path="/compressibility" element={<FluidCompressibilityPage />} />
                                                    <Route path="/casing_dimensions_strength" element={<CasingDimensionsPage />} />
                                                    <Route path="/drill_pipe_dimensions_strength" element={<DrillPipeLookup />} />
                                                    {/* <Route path="/pipe_stretch" element={<PipeStretchAndCollapse />} /> */}
                                                    <Route path="/casing_wear" element={<CasingWearCalculator />} />

                                                    <Route path="/capacities" element={<CapacityCalculationPage />} />
                                                    <Route path="/gas_migration" element={<GasMigrationRateCalculator />} />
                                                    <Route path="/pressure" element={<AnnularPressureLossForm />} />
                                                    <Route path="/temperature" element={<TemperatureCalculation />} />

                                                    <Route path="/bha_table" element={<BhaTable />} />
                                                    <Route path="/rheology_table" element={<RheologyTable />} />                           
                                                    <Route path="/private" element={<PrivatePage />} />
                                                    <Route path="/my_account" element={<Profile />} />
                                                    <Route path="/2fa_settings" element={<TwoFactorSettings />} />
                        <Route path="/subscription_success" element={<SubscriptionSuccess />} />
                        
                        {/* routes that require module subscriptions */}
                        <Route
                            path="/pipe_stretch"
                            element={
                                <ProtectedModuleRoute moduleName="Torque and Drag">
                                    <PipeStretchAndCollapse />
                                </ProtectedModuleRoute>
                            }
                        />
                        
                        <Route
                            path="/hydraulics"
                            element={
                                <ProtectedModuleRoute moduleName="Hydraulics">
                                    <HydraulicsPage />
                                </ProtectedModuleRoute>
                            }
                        />
                        <Route
                            path="/swab_and_surge"
                            element={
                                <ProtectedModuleRoute moduleName="Swab and Surge">
                                    <SwabAndSurgePage />
                                </ProtectedModuleRoute>
                            }
                        />
                        <Route
                            path="/kick_tolerance"
                            element={
                                <ProtectedModuleRoute moduleName="Kick Tolerance">
                                    <KickTolerancePage />
                                </ProtectedModuleRoute>
                            }
                        />
                        <Route
                            path="/torque_and_drag"
                            element={
                                <ProtectedModuleRoute moduleName="Torque and Drag">
                                    <TorqueAndDragPage />
                                </ProtectedModuleRoute>
                            }
                        />
                        <Route
                            path="/casing_design"
                            element={
                                <ProtectedModuleRoute moduleName="Casing Design">
                                    <CasingDesignPage />
                                </ProtectedModuleRoute>
                            }
                        />
                        <Route
                            path="/trajectory"
                            element={
                                <ProtectedModuleRoute moduleName="Trajectory Design">
                                    <TrajectoryDesignPage />
                                </ProtectedModuleRoute>
                            }
                        />
                        
                                                </>
                                            ) : (
                                                <Route path="*" element={<Navigate to="/login" setIsAuthenticated={setIsAuthenticated} setIsTwoFactorVerified={setIsTwoFactorVerified} />} />
                                            )}

                                            {/* 404 Route */}
                                            <Route path="*" element={<NotFound />} />
                                        
                {/* Admin routes */}
                {isAuthenticated && isAdmin && (
                    
                    <Route path="/admin/*" element={<AdminPanel />} />

                )}
                
            </Routes>

        </>
    );
};

export default AppContent;
