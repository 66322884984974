import React, { useContext } from "react";
import { ModulesContext } from "./ModulesContext";
import ActiveModules from "./ActiveModules";
import Breadcrumbs from "../../pages/tabs_components/Breadcrumb";
import { Col, Row, Container } from "react-bootstrap";

const ModulesDashboard = () => {
    const { activeModules, userId, loading, error, stripeCustomerId, availablePaidTools, freeTools } = useContext(ModulesContext);

    if (loading) return <p>Loading modules...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            <Breadcrumbs />
            <Container className="min-vh-100">
                <Row>
                    {/* Main Content Area */}
                    <Col xs={12} className="content-area mt-5">
                        <ActiveModules activeModules={activeModules} userId={userId} stripeCustomerId={stripeCustomerId} availablePaidTools={availablePaidTools} freeTools={freeTools} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ModulesDashboard;
