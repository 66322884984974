import { round } from 'lodash';
import { atan, cos, sin, tan, sqrt, acos, round as mathRound } from 'mathjs';

// Conversion constants
const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;

// Example of declaring variables
let w1 = 0; // or some computed value
let w2 = 0;
let w3 = 0;

let u1 = 0;
let u2 = 0;
let u3 = 0;

let v1 = 0;
let v2 = 0;
let v3 = 0;

let x1 = 0;
let x2 = 0;
let x3 = 0;

let k = 0;

let _H2 = 0;
let _H3 = 0;

let y1 = 0;
let y2 = 0;
let y3 = 0;

let z1 = 0;
let z2 = 0;
let z3 = 0;

export default function interpolateSurv(surveyData, ip) {
    // const length = surveyData.length;

    // Create a list of measured depths
    const mdList = surveyData.map((data) => data.md_survey);

    if (ip < 0 || ip > mdList[mdList.length - 1]) {
        throw new Error('Interpolation depth is out of range');
    }

    const res = mdList.findIndex((val) => val > ip);

    if (res === -1 || res === 0) {
        throw new Error('Interpolation point is out of range of survey data');
    }

    const md1 = surveyData[res - 1].md_survey;
    const tvd1 = surveyData[res - 1].tvd_survey;
    const inc1 = surveyData[res - 1].incl_survey * DEG_TO_RAD;
    const az1 = surveyData[res - 1].azim_survey * DEG_TO_RAD;

    const md2 = surveyData[res].md_survey;
    const tvd2 = surveyData[res].tvd_survey;
    const inc2 = surveyData[res].incl_survey * DEG_TO_RAD;
    const az2 = surveyData[res].azim_survey * DEG_TO_RAD;

    const md = md2 - md1;
    const dmd = ip - md1;

    let tvd, azimuth, inclination, dl, dls;

    if (ip === md1) {
        tvd = tvd1;
        azimuth = az1 * RAD_TO_DEG;
        inclination = inc1 * RAD_TO_DEG;
        dl = 0;
        dls = 0;
    } else if (inc1 === inc2 && inc2 === 0) {
        azimuth = az2 * RAD_TO_DEG;
        inclination = inc1 * RAD_TO_DEG;
        tvd = customRound(((tvd2 - tvd1) / md * dmd + tvd1), 2);
        dl = 0;
        dls = 0;
    } else if (inc1 === inc2 && az1 !== az2) {
        const dtvd = customRound(dmd * cos(inc1), 2);
        tvd = dtvd + tvd1;
        inclination = inc1 * RAD_TO_DEG;

        const dl2 = acos(cos(inc2 - inc1) - sin(inc2) * sin(inc1) * (1 - cos(az2 - az1)));
        dl = dl2 * dmd / md;
        dls = (dl * RAD_TO_DEG / dmd) * 100;

        // Determine azimuth at the interpolation point
        azimuth = calculateAzimuth(w1, w2, w3) * RAD_TO_DEG;
    } else if (az1 === az2 && inc1 !== inc2) {
        const dtvd = customRound((dmd * (sin(inc2) - sin(inc1)) / (inc2 - inc1)), 2);
        tvd = dtvd + tvd1;
        azimuth = az1 * RAD_TO_DEG;

        const dl2 = acos(cos(inc2 - inc1) - sin(inc2) * sin(inc1) * (1 - cos(az2 - az1)));
        dl = dl2 * dmd / md;
        dls = (dl * RAD_TO_DEG / dmd) * 100;

        // convert incl and azi input from cartesian coordinates to polar coordinates in a cartesian system
        u1 = Math.sin(inc1) * Math.cos(az1);
        u2 = Math.sin(inc1) * Math.sin(az1);
        u3 = Math.cos(inc1);

        v1 = Math.sin(inc2) * Math.cos(az2);
        v2 = Math.sin(inc2) * Math.sin(az2);
        v3 = Math.cos(inc2);

        x1 = u2 * v3 - u3 * v2;
        x2 = u3 * v1 - u1 * v3;
        x3 = u1 * v2 - u2 * v1;

        y1 = u2 * u3 - u3 * x2
        y2 = u3 * x1 - u1 * x3
        y3 = u1 * x2 - u2 * x1

        k = Math.sqrt(x1 ** 2 + x2 ** 2 + x3 ** 2)

        y1 = y1 / k
        y2 = y2 / k
        y3 = y3 / k

        x1 = x1 / k
        x2 = x2 / k
        x3 = x3 / k


        _H2 = -Math.sin(dl)
        _H3 = Math.cos(dl)

        w1 = _H2 * y1 + _H3 * z1
        w2 = _H2 * y2 + _H3 * z2
        w3 = _H2 * y3 + _H3 * z3

        inclination = calculateInclination(u1, u2, u3, v1, v2, v3, x1, x2, x3, dl);
    } else if (inc1 === inc2 && az1 === az2) {
        tvd = customRound(((tvd2 - tvd1) / (md2 - md1) * (ip - md1) + tvd1), 2);
        inclination = inc1 * RAD_TO_DEG;
        // azimuth = az1 * 180 / Math.pi;
        azimuth = round((az1 * RAD_TO_DEG),2);
        dl = 0;
        dls = 0;
    } else {        
        const dl2 = acos(cos(inc2 - inc1) - sin(inc2) * sin(inc1) * (1 - cos(az2 - az1)));
        dl = dl2 * dmd / md;

        dls = (dl * RAD_TO_DEG / dmd) * 100;
        tvd = calculateTVD(tvd1, inc1, inc2, md1, md2, ip, dl);

        // convert incl and azi input from cartesian coordinates to polar coordinates in a cartesian system
        u1 = Math.sin(inc1) * Math.cos(az1);
        u2 = Math.sin(inc1) * Math.sin(az1);
        u3 = Math.cos(inc1);

        v1 = Math.sin(inc2) * Math.cos(az2);
        v2 = Math.sin(inc2) * Math.sin(az2);
        v3 = Math.cos(inc2);

        x1 = u2 * v3 - u3 * v2;
        x2 = u3 * v1 - u1 * v3;
        x3 = u1 * v2 - u2 * v1;

        k = Math.sqrt(x1 ** 2 + x2 ** 2 + x3 ** 2)

        x1 = x1 / k
        x2 = x2 / k
        x3 = x3 / k

        y1 = u2 * u3 - u3 * x2
        y2 = u3 * x1 - u1 * x3
        y3 = u1 * x2 - u2 * x1

        k = Math.sqrt(y1 ** 2 + y2 ** 2 + y3 ** 2)

        y1 = y1 / k
        y2 = y2 / k
        y3 = y3 / k

        z1 = u1
        z2 = u2
        z3 = u3

        _H2 = -Math.sin(dl)
        _H3 = Math.cos(dl)

        w1 = _H2 * y1 + _H3 * z1
        w2 = _H2 * y2 + _H3 * z2
        w3 = _H2 * y3 + _H3 * z3

        inclination = calculateInclination(u1, u2, u3, v1, v2, v3, x1, x2, x3, dl);
        azimuth = calculateAzimuth(w1, w2, w3);

        console.log('inc1', inc1);
        console.log('inc2', inc2);
        console.log('md1', md1);
        console.log('md2', md2);
        console.log('ip', ip);
        console.log('md', md);
        console.log('tvd', tvd);
        console.log('tvd1', tvd1);
        console.log('inclination', inclination);
        console.log('azimuth', azimuth);

    }

    return { ip, inclination, azimuth, tvd, dl, dls };
}

function calculateAzimuth(w1, w2, w3) {
    let azimuth = atan(Math.abs(w2 / w1)) * RAD_TO_DEG;

    if (w1 < 0 && w2 < 0) azimuth = 180 + azimuth;
    if (w1 < 0 && w2 >= 0) azimuth = 180 - azimuth;
    if (w1 >= 0 && w2 < 0) azimuth = 360 - azimuth;

    return customRound(azimuth, 2);
}

function calculateInclination(u1, u2, u3, v1, v2, v3, x1, x2, x3, dl) {
    const k = sqrt(x1 ** 2 + x2 ** 2 + x3 ** 2);
    const y1 = u2 * u3 - u3 * x2;
    const y2 = u3 * x1 - u1 * x3;
    const y3 = u1 * x2 - u2 * x1;

    const H2 = -sin(dl);
    const H3 = cos(dl);

    const w1 = H2 * (y1 / k) + H3 * u1;
    const w2 = H2 * (y2 / k) + H3 * u2;
    const w3 = H2 * (y3 / k) + H3 * u3;

    return customRound((atan(sqrt(w1 ** 2 + w2 ** 2) / Math.abs(w3)) * RAD_TO_DEG), 2);
}

function calculateTVD(tvd1, inc1, inc2, md1, md2, ip, dl) {
    const dmd = ip - md1;
    const r = tan(dl) / dl;
    return customRound(tvd1 + (dmd / 2) * (cos(inc1) + cos(inc2)) * r, 2);
}

function customRound(value, decimals) {
    return Number(mathRound(value + 'e' + decimals) + 'e-' + decimals);
}
