import React, { useState } from 'react';
import { Row, Col, Container } from "react-bootstrap";

import TorqueAndDragTabs from './tabs_components/TorqueAndDragTabs';
import HelpComponent from '../components/help/helpComponent';
import LeftSidebar from './tabs_components/LeftSidebar';
import Breadcrumbs from './tabs_components/Breadcrumb';
import RightSidebar from './tabs_components/RightSidebar';


function TorqueAndDragPage() {
    // const { selectedItems } = useContext(GlobalStateContext);
    const [showHelp, setShowHelp] = useState(false); // State for showing help modal

    return (
        <>
            <Breadcrumbs />

            <Container>
                <Row>
                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <LeftSidebar />

                    </Col>
                    <Col xs={12} md={6} lg={8} className="content-area">

                        <TorqueAndDragTabs />
                    </Col>

                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <RightSidebar />

                    </Col>
                </Row>
                <HelpComponent show={showHelp} handleClose={() => setShowHelp(false)} section="kickTolerance" />
            </Container>
        </>
    );
}

export default TorqueAndDragPage;