

import React, { useContext } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import DrillingDesignCalculator from '../../components/modules/torque_and_drag/DrillingDesignCalculator';




import { GlobalStateContext } from "../../components/GlobalStateContext";

function TorqueAndDragTabs() {
    const { selectedItems } = useContext(GlobalStateContext);

    return (

        <Tabs
            id="controlled-tab-well_information"
            defaultActiveKey="torque_and_drag"
            className="mb-3 mt-5"
        >
            <Tab eventKey="home_tnd" title="Home">
                <div className="">
                    <div className="">
                        <h5>Torque and drag imulations</h5>
                        {/* <p>Selected company: {selectedItems.company_name}: {selectedItems.block_name}: {selectedItems.field_name}</p> */}
                        <p>View results for {selectedItems.wellbore_name} {selectedItems.well_design_case_name}:</p>
                        <ul>
                            <li>View torque and drag results</li>
                            <li>Perform sensitivity analysis</li>
                            <li>View charts</li>
                        </ul>
                    </div>


                </div>
            </Tab>

            <Tab eventKey="torque_and_drag" title="Torque and Drag" >
                <div >
                    <p>
                        {selectedItems.wellbore_name}: Torque and Drag simulations for {selectedItems.well_design_case_name}
                    </p>
                </div>
                <DrillingDesignCalculator wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
            </Tab>
        </Tabs>
    );
}

export default TorqueAndDragTabs;
