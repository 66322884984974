import React, { createContext, useEffect, useState, useMemo } from "react";
import axios from "axios";

export const ModulesContext = createContext();

const hydraulicsTools = ["Annular Pressure Loss", "Mud Compressibility", "Temperature"];
const torqueAndDragTools = ["Pipe Stretch", "Side Forces"];
const casingDesignTools = ["Casing Wear"];
const trajectoryDesignTools = ["Interpolation"];
const kickToleranceTools = ["Gas Migration"];

const paidToolsMap = {
    Hydraulics: hydraulicsTools,
    "Torque and Drag": torqueAndDragTools,
    "Casing Design": casingDesignTools,
    "Trajectory Design": trajectoryDesignTools,
    "Kick Tolerance": kickToleranceTools,
};

const paidTools = Object.keys(paidToolsMap);

const freeTools = [
    "Units converter",
    "Casing dimensions and strengths",
    "Drill pipe dimensions and strengths",
    "Capacities"
];

export const ModulesProvider = ({ children }) => {
    const [activeModules, setActiveModules] = useState([]);
    const [userId, setUserId] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stripeCustomerId, setStripeCustomerId] = useState(null);
    const [availablePaidTools, setAvailablePaidTools] = useState([]);
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.get(`${baseURL}/verify_auth`, { withCredentials: true });
                const fetchedUserId = userResponse?.data?.user_id;

                if (!fetchedUserId) {
                    throw new Error("User ID not found.");
                }
                setUserId(fetchedUserId);
                setFullName(userResponse?.data?.full_name);
                setStripeCustomerId(userResponse?.data?.stripe_customer_id);

                const response = await axios.get(`${baseURL}/user/active-modules`, {
                    headers: { "User-Id": fetchedUserId },
                    withCredentials: true,
                });

                const activeModulesData = response?.data || [];
                setActiveModules(activeModulesData);
            } catch (err) {
                setError(err.response?.data?.error || err.message || "Failed to fetch data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [baseURL]);

    const availablePaidToolsMemo = useMemo(() => {
        return paidTools
            .filter(tool => activeModules.includes(tool))
            .flatMap(tool => paidToolsMap[tool]);
    }, [activeModules]);

    useEffect(() => {
        if (availablePaidToolsMemo.length > 0) {
            setAvailablePaidTools(availablePaidToolsMemo);
        } else {
            setAvailablePaidTools(["You don't have access to any premium tools."]);
        }
    }, [availablePaidToolsMemo]);

    return (
        <ModulesContext.Provider
            value={{ activeModules, userId, loading, error, stripeCustomerId, availablePaidTools, fullName, freeTools }}
        >
            {children}
        </ModulesContext.Provider>
    );
};
