import React, { useState, useContext } from "react";
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { ModulesContext } from "../admin/ModulesContext";

const PipeStretchAndCollapse = () => {
    const [pipeLength, setPipeLength] = useState(0); // in feet or meters
    const [pipeWeight, setPipeWeight] = useState(0); // in lbs/ft or kg/m
    const [pipeOD, setPipeOD] = useState(0); // Outer diameter in inches or mm
    const [pipeID, setPipeID] = useState(0); // Inner diameter in inches or mm
    const [elasticModulus, setElasticModulus] = useState(30e6); // Young's modulus in psi
    const [collapsePressure, setCollapsePressure] = useState(0); // psi

    const [stretchResult, setStretchResult] = useState(null);
    const [collapseResult, setCollapseResult] = useState(null);

    const { activeModules } = useContext(ModulesContext);

    const calculateStretchAndCollapse = () => {
        if (!pipeLength || !pipeWeight || !pipeOD || !pipeID || !elasticModulus) {
            alert("Please fill in all fields.");
            return;
        }

        // Convert inputs to consistent units if needed
        const weightPerFoot = pipeWeight; // Assuming user provides the correct unit
        const pipeArea = Math.PI * ((pipeOD / 2) ** 2 - (pipeID / 2) ** 2); // Cross-sectional area in square inches
        const force = weightPerFoot * pipeLength; // Tensile force in lbs
        const stretch = (force * pipeLength) / (elasticModulus * pipeArea); // Stretch in inches

        // Collapse pressure calculation based on pipe dimensions (approximation)
        const wallThickness = (pipeOD - pipeID) / 2; // Wall thickness in inches
        const collapsePressureCalc = (2 * elasticModulus * wallThickness) / pipeOD; // Simplified formula

        setStretchResult({
            force: force.toFixed(2),
            stretch: stretch.toFixed(5),
        });

        setCollapseResult({
            collapsePressure: collapsePressureCalc.toFixed(2),
        });
    };

    if (!activeModules.includes("Torque and Drag")) {
        <div>
            Please subscribe to the Torque and Drag modules to use this tool.
        </div>
    }

    return (
        <div className="container mt-3 min-vh-100">
            <h3>Pipe Stretch and Collapse Calculator</h3>
            <Form>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="pipeLength">
                            <Form.Label>Pipe Length (ft or m)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter pipe length"
                                value={pipeLength}
                                onChange={(e) => setPipeLength(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="pipeWeight">
                            <Form.Label>Pipe Weight (lbs/ft or kg/m)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter pipe weight"
                                value={pipeWeight}
                                onChange={(e) => setPipeWeight(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="pipeOD">
                            <Form.Label>Pipe Outer Diameter (in or mm)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter pipe outer diameter"
                                value={pipeOD}
                                onChange={(e) => setPipeOD(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="pipeID">
                            <Form.Label>Pipe Inner Diameter (in or mm)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter pipe inner diameter"
                                value={pipeID}
                                onChange={(e) => setPipeID(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="elasticModulus">
                            <Form.Label>Elastic Modulus (psi)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter elastic modulus"
                                value={elasticModulus}
                                onChange={(e) => setElasticModulus(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="collapsePressure">
                            <Form.Label>Collapse Pressure (psi, optional)</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Optional collapse pressure"
                                value={collapsePressure}
                                onChange={(e) => setCollapsePressure(parseFloat(e.target.value))}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button
                    className="mt-3"
                    variant="primary"
                    onClick={calculateStretchAndCollapse}
                >
                    Calculate
                </Button>
            </Form>

            {stretchResult && (
                <div className="mt-4">
                    <h5>Stretch Results</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Force (lbs)</td>
                                <td>{stretchResult.force}</td>
                            </tr>
                            <tr>
                                <td>Stretch (inches)</td>
                                <td>{stretchResult.stretch}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}

            {collapseResult && (
                <div className="mt-4">
                    <h5>Collapse Results</h5>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Collapse Pressure (psi)</td>
                                <td>{collapseResult.collapsePressure}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default PipeStretchAndCollapse;
