import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { GlobalStateContext } from "../../GlobalStateContext";
// import { useHoleSection } from "../../HoleSectionProvider";

const BitDataContext = createContext();

export const BitDataProvider = ({ children, baseURL }) => {
    const [allBitData, setAllBitData] = useState([]);
    const [filteredBits, setFilteredBits] = useState([]);
    const [bitToEdit, setBitToEdit] = useState(null);
    const [defaultBitId, setDefaultBitId] = useState(null);
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);

    // const { selectedHoleItems } = useHoleSection();
    // const holeSize = selectedHoleItems?.openHoleParameters?.hole_size;
    const holeSize = selectedItems?.default_hole_size;


    console.log('holeSize---1', holeSize);


    const fetchBits = () => {
        axios.get(`${baseURL}/input/bit_id/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`, {
            withCredentials: true,
        })
            .then(response => {
                const all_bit_data = response.data;
                setAllBitData(all_bit_data);
                // console.log('all_bit_data', all_bit_data);

                // const holeSize = selectedItems.holeSize;
                // const holeSize = selectedHoleItems?.openHoleParameters?.hole_size;
                console.log('holeSize----', holeSize);

                const filtered = all_bit_data.filter(bit => bit.bit_size <= holeSize);
                setFilteredBits(filtered);
                // console.log('filtered', filtered);

                setDefaultBitId(filtered[0].drill_bit_id);

                if (filtered.length === 1) {
                    setSelectedItems(prevState => ({
                        ...prevState,
                        default_bit_id: filtered[0].drill_bit_id,
                    }));
                }
            })
            .catch(error => {
                console.error("Error fetching drill bit data:", error);
            });
    };

    const deleteBit = (bitId) => {
        axios.delete(`${baseURL}/input/bit/${bitId}`, {
            withCredentials: true,
        })
            .then(() => {
                fetchBits();
            })
            .catch(error => {
                console.error("Error deleting drill bit:", error);
            });
    };

    const addBit = (bitData, callback) => {
        axios.post(`${baseURL}/input/bit`, bitData, {
            withCredentials: true,
        })
            .then(() => {
                fetchBits();
                if (callback) callback();
            })
            .catch(error => {
                console.error("Error adding drill bit:", error);
            });
    };

    const updateBit = (updatedBit, callback) => {
        axios.put(`${baseURL}/input/bit/${updatedBit.drill_bit_id}`, updatedBit, {
            withCredentials: true,
        })
            .then(() => {
                fetchBits();
                if (callback) callback();
            })
            .catch(error => {
                console.error("Error updating drill bit:", error);
            });
    };

    useEffect(() => {
        fetchBits();
    }, [selectedItems.well_id, selectedItems.wellbore_id, selectedItems.well_design_case_id]);

    return (
        <BitDataContext.Provider
            value={{
                allBitData,
                filteredBits,
                bitToEdit,
                setBitToEdit,
                deleteBit,
                addBit,
                updateBit,
                defaultBitId,
            }}
        >
            {children}
        </BitDataContext.Provider>
    );
};

export const useBitData = () => useContext(BitDataContext);
