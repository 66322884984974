import React, { useState } from 'react';

import { Col, Row, Container } from 'react-bootstrap';

import HelpComponent from '../components/help/helpComponent';
import DesignCaseTabs from '../components/design_headers/designCaseTabs';
import RightSidebar from './tabs_components/RightSidebar';
import LeftSidebar from './tabs_components/LeftSidebar';
import Breadcrumbs from './tabs_components/Breadcrumb';


function DesignCasePage(isAuthenticated) {
    const [showHelp, setShowHelp] = useState(false); // State for showing help modal

    return (
        <>
            <Breadcrumbs />

            <Container>
                <Row>
                    {/* Left Sidebar */}
                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <LeftSidebar />
                    </Col>

                    {/* Main Content Area */}
                    <Col xs={12} md={6} lg={8} className="content-area">
                        {isAuthenticated &&
                            <DesignCaseTabs/>
                        }
                    </Col>

                    {/* Right Sidebar (Optional) */}
                    <Col xs={12} md={3} lg={2} className="d-none d-md-block">
                        <RightSidebar />

                    </Col>
                </Row>
                <HelpComponent show={showHelp} handleClose={() => setShowHelp(false)} section="designCase" />
            </Container>
        </>
        
    );
}

export default DesignCasePage;