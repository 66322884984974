import React, { useContext, useState, useEffect } from 'react';

import {Alert, Button, Tab, Tabs, Modal, Dropdown} from 'react-bootstrap';

import RheologyHeader from "../../components/well_information/rheology/rheology";
import RigEquipmentHeader from "../../components/well_information/rig_equipment/rigEquipment";
import BitHeader from "../../components/well_information/bits/bit";
// import BhaHeader from '../../components/well_information/BhaHeader';
import BhaHeader from '../../components/well_information/bha_items/BhaHeader';
import EarthModelTable from '../../components/well_information/earth_model/EarthModelTable';
import { GlobalStateContext } from "../../components/GlobalStateContext";
import DrillingParametersTable from '../../components/well_information/drilling_parameters/DrillingParametersTable';
// import { useHoleSection } from "./HoleSectionProvider";
import { useNavigate } from 'react-router-dom';
import { ModulesContext } from '../../components/admin/ModulesContext';

function WellInformationTabs() {
    const { selectedItems } = useContext(GlobalStateContext);
    const [activeKey, setActiveKey] = useState("rheology");
    const navigate = useNavigate();
    const { activeModules } = useContext(ModulesContext);
    const default_bit_id = selectedItems?.default_bit_id;
    const [showDefaultHoleModal, setShowDefaultHoleModal] = useState(false); // State to control the modal
    // const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    useEffect(() => {
        // checkDefaultHole();
        const checkDefaultHole = () => {
            if (!selectedItems?.hole_default_parameter_id) {
                // navigate('/design_case');
                // window.location.href = "/design_case";
                setShowDefaultHoleModal(true); // Show modal instead of alert
            }
        };
        checkDefaultHole();
    });


    // when this function is called, check if there is a default hole section, 
    // if there is, proceed and load this page, if not, display alert modal, and redirect to well info
    // const checkDefaultHole = () => {
    //     if (!selectedItems?.hole_default_parameter_id) {
    //         // navigate('/design_case');
    //         // window.location.href = "/design_case";
    //         setShowDefaultHoleModal(true); // Show modal instead of alert
    //     }
    // };

    // Determine active modules for tabs
    const bitModules = ["Hydraulics", "Swab and Surge"].some(module => activeModules.includes(module));
    const bhaModules = ["Hydraulics", "Torque and Drag", "Kick Tolerance", "Swab and Surge"].some(module => activeModules.includes(module));
    const equipmentModules = ["Hydraulics", "Swab and Surge", "Torque and Drag"].some(module => activeModules.includes(module));
    const emModules = ["Casing Design", "Kick Tolerance"].some(module => activeModules.includes(module));
    const drillParameterModules = ["Hydraulics", "Torque and Drag", "Kick Tolerance", "Swab and Surge"].some(module => activeModules.includes(module));

    // Dynamically construct tabOrder
    const tabOrder = [
        "tips_well_info",
        "rheology",
        ...(bitModules ? ["drill_bits"] : []),
        ...(bhaModules ? ["string"] : []),
        ...(equipmentModules ? ["rig_equipment"] : []),
        ...(emModules ? ["earth_model"] : []),
        ...(drillParameterModules ? ["drilling_parameters"] : []),
    ];


    const handleNext = () => {
        const currentIndex = tabOrder.indexOf(activeKey);
        const nextIndex = currentIndex + 1;

        // Check if the current tab is drill_bits and default_bit_id is not set
        if (activeKey === "drill_bits" && !default_bit_id) {
            setShowModal(true); // Show the modal
            return; // Do not proceed to the next tab
        }

        if (nextIndex < tabOrder.length) {
            setActiveKey(tabOrder[nextIndex]);
        }
    };

    const [showModal, setShowModal] = useState(false); // State to control the modal

    const handlePrevious = () => {
        // const tabOrder = ["tips_well_info", "rheology", "drill_bits", "string", "rig_equipment", "earth_model", "drilling_parameters"];
        const currentIndex = tabOrder.indexOf(activeKey);
        const previousIndex = currentIndex - 1;

        if (previousIndex < tabOrder.length) {
            setActiveKey(tabOrder[previousIndex]);
        }
    };

    const navigateToDesignCase = () => {
        setShowDefaultHoleModal(false);
        navigate('/design_case');
        // setActiveKey("holes")
    };

    // Function to map module names to URLs
    const getModuleUrl = (module) => {
        let url;
        switch (module) {
            case "Hydraulics":
                url = '/hydraulics';
                break;
            case "Swab and Surge":
                url = '/swab_and_surge';
                break;
            case "Torque and Drag":
                url = '/torque_and_drag';
                break;
            case "Casing Design":
                url = '/casing_design';
                break;
            case "Kick Tolerance":
                url = '/kick_tolerance';
                break;
            default:
                url = '/'; // Default fallback URL
                break;
        }
        return url;
    };

    const navigateToModule = (module) => {
        const url = getModuleUrl(module);
        navigate(url);
    };

    return (
        <>
            <Tabs
                id="controlled-tab-well_information"
                // defaultActiveKey="rheology"
                activeKey={activeKey} // Bind the state to the Tabs component
                onSelect={(key) => setActiveKey(key)} // Update state when a tab is selected
                className="mb-3 mt-5"
            >
                <Tab eventKey="tips_well_info" title="Tips">
                    <div className="">
                        <div className='text-end'>
                            <Button variant='outline-primary' onClick={navigateToDesignCase}>
                                Previous
                            </Button>{' '}
                            <Button variant='primary' onClick={handleNext}>
                                Next
                            </Button>
                        </div>
                        <div className="">
                            <h5>Well Information</h5>
                            {/* <p>Selected company: {selectedItems.company_name}: {selectedItems.block_name}: {selectedItems.field_name}</p> */}
                            <p>Create, read, update and delete well information for {selectedItems.wellbore_name} {selectedItems.well_design_case_name}:</p>
                            <ul>
                                <li>Rheology</li>
                                <li>Drill bits</li>
                                <li>String/BHA</li>
                                <li>Rig equipment</li>
                                <li>Earth model</li>
                                <li>Drilling parameters</li>
                            </ul>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="rheology" title="Rheology">
                    <div className='text-end'>
                        <Button variant='outline-primary' onClick={navigateToDesignCase}>
                            Previous
                        </Button>{' '}
                        <Button variant='primary' onClick={handleNext}>
                            Next
                        </Button>
                    </div>
                    <div className="mx-4">
                        <p>
                            {selectedItems.wellbore_name}: Rheology information for {selectedItems.well_design_case_name}
                        </p>
                    </div>
                    <RheologyHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
                </Tab>
                {bitModules && (
                    <Tab eventKey="drill_bits" title="Drill Bits" >
                        <div className='text-end'>
                            <Button variant='outline-primary' onClick={handlePrevious}>
                                Previous
                            </Button>{' '}
                            <Button variant='primary' onClick={handleNext}>
                                Next
                            </Button>
                        </div>
                        <div className="mx-4">
                            <p>
                                {selectedItems.wellbore_name}: Drill bit information for {selectedItems.well_design_case_name}

                            </p>
                            <Alert>Drill bit information in this section is used in Hydraulics, and Swab and Surge modules.</Alert>

                        </div>
                        <BitHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
                    </Tab>
                )}
                {bhaModules && (
                    <Tab eventKey="string" title="String/BHA">
                        <div className='text-end'>
                            <Button variant='outline-primary' onClick={handlePrevious}>
                                Previous
                            </Button>{' '}
                            <Button variant='primary' onClick={handleNext}>
                                Next
                            </Button>
                        </div>
                        <div className="mx-4">
                            <p>
                                {selectedItems.wellbore_name}: BHA information for {selectedItems.well_design_case_name}
                            </p>
                            <Alert>BHA information in this section is used in Hydraulics, Swab and Surge, Kick Tolerance and Torque and Drag modules.</Alert>

                        </div>

                        <BhaHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
                    </Tab>
                )}
                {equipmentModules && (
                    <Tab eventKey="rig_equipment" title="Rig equipment" >
                        <div className='text-end'>
                            <Button variant='outline-primary' onClick={handlePrevious}>
                                Previous
                            </Button>{' '}
                            <Button variant='primary' onClick={handleNext}>
                                Next
                            </Button>
                        </div>
                        <div className="mx-4">
                            <p>
                                {selectedItems.wellbore_name}: Rig equipment information for {selectedItems.well_design_case_name}
                            </p>
                            <Alert>Pump rate information in this section is used in Hydraulics, Swab and Surge, and Torque and Drag modules.</Alert>

                        </div>
                        <RigEquipmentHeader wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
                    </Tab>
                )}
                {emModules && (
                    <Tab eventKey="earth_model" title="Earth model" >
                        <div className='text-end'>
                            <Button variant='outline-primary' onClick={handlePrevious}>
                                Previous
                            </Button>{' '}
                            <Button variant='primary' onClick={handleNext}>
                                Next
                            </Button>
                        </div>
                        <div className="mx-4">
                            <p>
                                {selectedItems.wellbore_name}: Earth Model data for {selectedItems.well_design_case_name}
                            </p>
                            <Alert>The information in this section is used in Kick Tolerance and Casing Design modules.</Alert>

                        </div>
                        <EarthModelTable wellboreId={selectedItems.wellbore_id} wellId={selectedItems.well_id} wellDesignCaseId={selectedItems.well_design_case_id} />
                    </Tab>
                )}
                {drillParameterModules && (
                    <Tab eventKey="drilling_parameters" title="Drilling parameters">
                        <div className="d-flex justify-content-end align-items-center">
                            <div className='d-flex'>
                                <div>
                                    <Button variant="outline-primary" onClick={handlePrevious} className='me-2'>
                                        Previous
                                    </Button>
                                </div>
                                {/* Dropdown for module selection */}
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                        Select Module
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {activeModules.map((module, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                onClick={() => navigateToModule(module)}
                                            >
                                                {module}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="mx-4">
                            <p>
                                {selectedItems.wellbore_name}: Drilling Parameters for {selectedItems.well_design_case_name}
                            </p>
                            <Alert>
                                Drilling parameters entered here are used in the Hydraulics, Swab and
                                Surge, and Torque and Drag modules.
                            </Alert>
                        </div>
                        <DrillingParametersTable
                            wellboreId={selectedItems.wellbore_id}
                            wellId={selectedItems.well_id}
                            wellDesignCaseId={selectedItems.well_design_case_id}
                        />
                    </Tab>

                )}

            </Tabs>
            {/* Modal for "Please select a default drill bit" */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Action Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please select a drill bit!</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal for missing default hole section */}
            <Modal show={showDefaultHoleModal} onHide={navigateToDesignCase}>
                <Modal.Header closeButton>
                    <Modal.Title>Default Hole Section Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please set a default hole section before proceeding.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={navigateToDesignCase}>
                        Go to Design Case
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WellInformationTabs;
