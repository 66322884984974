import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
// import { GlobalStateContext } from '../../GlobalStateContext';

function EditHwdpElementForm({
    show,
    handleClose,
    bhaElement, // The BHA element to edit
    wellId,
    wellboreId,
    wellDesignCaseId,
    onBhaItemUpdated
}) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables

    // const { selectedItems } = useContext(GlobalStateContext);


    const size = bhaElement?.dp_size ?? bhaElement?.hwdp_size ?? bhaElement?.dc_od ?? bhaElement?.jar_size ?? bhaElement?.casing_size;
    console.log('size:', size);

    const [od, setOd] = useState([]);
    const [nomWeights, setNomWeights] = useState([]);
    // const [dpGrade, setDpGrade] = useState(null);
    const [dpConn, setDpConn] = useState(null);
    const [dpConnOd, setDpConnOd] = useState(null);
    const [dpConnId, setDpConnId] = useState(null);

    const dpRange = ['1', '2', '3'];
    const dpClass = ['New', 'Premium', 'C-1', 'C-2', 'C-3'];
    const dpGrade = ['4145H', '4142H', 'HT55', 'V-125', 'Stainless']

    console.log('bhaElement', bhaElement);
    console.log('dpConnOd', dpConnOd);
    console.log('dpConnId', dpConnId);
    console.log('dp_nom_wt', bhaElement?.dp_nom_wt || bhaElement?.hwdp_wt_incl_tj || bhaElement?.dc_wt || bhaElement?.total_wt || bhaElement?.casing_wt);

    const [formData, setFormData] = useState({
        bha_type: bhaElement?.type,
        // tool_unique_id: bhaElement?.tool_unique_id,
        dp_size: bhaElement?.dp_size || bhaElement?.dc_od || bhaElement?.jar_size || bhaElement?.casing_size,
        dp_nom_wt: bhaElement?.dp_nom_wt || bhaElement?.hwdp_wt_incl_tj || bhaElement?.dc_wt || bhaElement?.total_wt || bhaElement?.casing_wt,
        dp_id: bhaElement?.dp_id || bhaElement?.hwdp_int_dia || bhaElement?.dc_id || bhaElement?.jar_id || bhaElement?.casing_id,
        dp_grade: bhaElement?.dp_grade,
        dp_conn: bhaElement?.dp_conn || bhaElement?.hwdp_conn || bhaElement?.dc_number || bhaElement?.tj_conn || bhaElement?.seal,
        dp_tj_od: bhaElement?.tool_jt_od,
        dp_tj_id: bhaElement?.tool_jt_id,
        tool_class: bhaElement?.tool_class,
        range_length: bhaElement?.range_length,
        tool_length: bhaElement?.tool_length,
    });

    const bhaType = bhaElement?.type;
    console.log('bhaType', bhaType);
    console.log('formData.dp_size', formData.dp_size);

    function getlibrary(bha_type) {
        switch (bha_type) {
            case "Drill pipe":
                return 'dp_library';
            case "HWDP":
                return 'hwdp_library';
            case "Drill collar":
                return 'drill_collar_library';
            case "Jar":
                return 'jar_library';
            case "Casing":
                return 'casing_library';
            default:
                return 'dp_library';
        }
    }

    useEffect(() => {
        if (bhaElement) {
            setFormData({
                bha_type: bhaElement?.type,
                // tool_unique_id: bhaElement?.tool_unique_id,
                dp_size: bhaElement?.dp_size || bhaElement?.dc_od || bhaElement?.jar_size || bhaElement?.casing_size,
                dp_nom_wt: bhaElement?.dp_nom_wt || bhaElement?.hwdp_wt_incl_tj || bhaElement?.dc_wt || bhaElement?.total_wt || bhaElement.casing_wt,
                dp_id: bhaElement?.dp_id || bhaElement?.hwdp_int_dia || bhaElement?.dc_id || bhaElement?.jar_id || bhaElement?.casing_id,
                dp_grade: bhaElement?.dp_grade,
                dp_conn: bhaElement?.dp_conn || bhaElement?.hwdp_conn || bhaElement?.dc_number || bhaElement?.tj_conn || bhaElement?.seal,
                dp_tj_od: bhaElement?.dp_tj_od,
                dp_tj_id: bhaElement?.dp_tj_id,
                tool_class: bhaElement?.tool_class,
                range_length: bhaElement?.range_length,
                tool_length: bhaElement?.tool_length,
            });
        }

        const fetchDrillPipeData = () => {
            const library = getlibrary(bhaType); // Determine library dynamically

            axios
                .get(`${baseURL}/input/${library}/unique_od`, { withCredentials: true })
                .then(response => {
                    const processedOdValues = processOdValues(response.data.od_values);
                    console.log('processedOdValues:', processedOdValues);

                    setOd(processedOdValues);

                    // Ensure dp_size is updated if it doesn't match any processed OD values
                    if (size) {
                        const matchingValue = processedOdValues.find(
                            odValue => odValue === parseFloat(processOdValue(size)).toFixed(1)
                        );
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            dp_size: matchingValue || '', // Use matching value or leave empty if none found
                        }));
                    }
                })
                .catch(error => {
                    console.error('Error fetching unique ODs available in db:', error);
                });
        };

        fetchDrillPipeData();

    }, [baseURL, bhaElement, bhaType, size]);


    const processOdValues = (odValues) => {
        return odValues.map(od => {
            od = od.toString();
            if (!od.includes('.')) {
                return parseFloat(od).toFixed(1);
            }
            return od;
        });
    };

    const processOdValue = (odValue) => {
        const od = odValue.toString();
        if (!od.includes('.')) {
            return parseFloat(od).toFixed(1);
        }
    };

    if (size) {
        console.log('size:', size);
        const sizeProcessed = processOdValue(size);
        console.log('sizeProcessed:', sizeProcessed);
    }

    // const processOdValuesNumeral = (odValues) => {
    //     return odValues.map(od => {
    //         od = parseFloat(od);

    //         return od;
    //     });
    // };

    // console.log('formData', formData);
    // console.log('od:', od);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleOdChange = (e) => {
        const dpSize = e.target.value;
        const library = getlibrary(bhaType); // Determine library dynamically

        setFormData({ ...formData, dp_size: dpSize });

        axios.get(`${baseURL}/input/${library}/${dpSize}`, { withCredentials: true })
            .then(response => {

                // console.log('API response for nomWeights:', response.data); // Log the response

                setNomWeights(response.data);
                // console.log('nomWeights:', response.data);

            })
            .catch(error => {
                console.error('Error fetching dp nom wt:', error);
            });
    };

    // Trigger fetching nominal weights on mount or when bhaElement.dp_size changes
    // console.log('formData.dp_size', formData.dp_size);

    useEffect(() => {
        if (formData.dp_size) {
            const library = getlibrary(bhaType); // Determine library dynamically

            // Fetch nominal weights based on dp_size
            const fetchNomWeights = (dpSize) => {
                if (!dpSize) return; // Skip if dpSize is empty or undefined
                axios
                    .get(`${baseURL}/input/${library}/${dpSize}`, { withCredentials: true })
                    .then((response) => {

                        console.log("Fetched nomWeights:", response.data);
                        setNomWeights(response.data);

                        if (bhaType === "Drill collar") {
                            const parsedNomWeights = response.data.map(item => {
                                if (Number.isInteger(item.dc_wt)) {
                                    return { ...item, dc_wt: parseFloat(item.dc_wt).toFixed(1) };
                                }
                                return item;
                            });
                            console.log('parsedNomWeights', parsedNomWeights);

                            setNomWeights(parsedNomWeights);
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching dp nom wt:", error);
                    });
            };

            fetchNomWeights(formData.dp_size);
        }
    }, [baseURL, formData.dp_size, bhaType]);

    // useEffect(() => {
    //     if (formData.dp_size && formData.dp_nom_wt) {
    //         const library = getlibrary(bhaType); // Determine library dynamically

    //         // get pipe grades
    //         const fetchPipeGrades = (dp_size, nom_wt) => {
    //             if (dp_size && nom_wt) {
    //                 axios.get(`${baseURL}/input/${library}/${dp_size}/${nom_wt}`, { withCredentials: true })
    //                     .then(response => {
    //                         setDpGrade(response.data.dp_grade);
    //                     })
    //                     .catch(error => {
    //                         console.error('Error fetching pipe grade:', error);
    //                     });
    //             }
    //         };

    //         fetchPipeGrades(formData.dp_size, formData.dp_nom_wt);
    //     }
    // }, [baseURL, formData.dp_nom_wt, formData.dp_size, bhaType]);

    // console.log('formData.dp_grade', formData.dp_grade);

    useEffect(() => {
        if (formData.dp_size && formData.dp_nom_wt && formData.dp_grade) {

            const library = getlibrary(bhaType); // Determine library dynamically

            const fetchPipeConn = (dp_size, nom_wt, dp_grade) => {
                axios
                    .get(`${baseURL}/input/${library}/${dp_size}/${nom_wt}/${dp_grade}`, { withCredentials: true })
                    .then(response => {
                        setDpConn(response.data.dp_conn);
                    })
                    .catch(error => {
                        console.error('Error fetching pipe connection:', error);
                    });
            };

            fetchPipeConn(formData.dp_size, formData.dp_nom_wt, formData.dp_grade);
        }
    }, [formData.dp_size, formData.dp_nom_wt, formData.dp_grade, baseURL, bhaType]); // Include all dependencies


    const handleNomWtChange = (e) => {
        const dpNomWt = e.target.value;
        // const library = getlibrary(bhaType); // Determine library dynamically

        setFormData({ ...formData, dp_nom_wt: dpNomWt });

        // axios.get(`${baseURL}/input/${library}/${formData.dp_size}/${dpNomWt}`, { withCredentials: true })
        //     .then(response => {
        //         setDpGrade(response.data.dp_grade);
        //     })
        //     .catch(error => {
        //         console.error('Error fetching DP grade:', error);
        //     });
    };

    const handleGradeChange = (e) => {
        const dpGrade = e.target.value;
        // const library = getlibrary(bhaType); // Determine library dynamically

        setFormData({ ...formData, dp_grade: dpGrade });

        // axios.get(`${baseURL}/input/${library}/${formData.dp_size}/${formData.dp_nom_wt}/${dpGrade}`, { withCredentials: true })
        //     .then(response => {
        //         setDpConn(response.data.dp_conn);
        //     })
        //     .catch(error => {
        //         console.error('Error fetching DP connection:', error);
        //     });
    };

    const handleConnChange = (e) => {
        const dpConn = e.target.value;
        const library = getlibrary(bhaType); // Determine library dynamically

        setFormData({ ...formData, dp_conn: dpConn });

        axios.get(`${baseURL}/input/${library}/${formData.dp_size}/${formData.dp_nom_wt}/${formData.dp_grade}/${dpConn}`, { withCredentials: true })
            .then(response => {
                setDpConnOd(response.data.dp_tj_od);
                setDpConnId(response.data.dp_tj_id);
            })
            .catch(error => {
                console.error('Error fetching connection details:', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios({
            method: "PATCH",
            url: `${baseURL}/input/bha_items/${bhaElement.bha_item_id}`, // Update endpoint
            withCredentials: true,
            data: {
                well_id: parseInt(wellId),
                wellbore_id: parseInt(wellboreId),
                well_design_case_id: parseInt(wellDesignCaseId),
                bha_type: formData.type,
                tool_unique_id: parseInt(formData.tool_unique_id),
                dp_size: parseFloat(formData.dp_size),
                dp_nom_wt: parseFloat(formData.dp_nom_wt),
                dp_id: parseFloat(formData.dp_id),
                dp_tj_od: parseFloat(formData.dp_tj_od),
                dp_tj_id: parseFloat(formData.dp_tj_id),
                tool_class: formData.tool_class,
                dp_grade: formData.dp_grade,
                dp_conn: formData.dp_conn,
                range_length: parseFloat(formData.range_length),
                tool_length: parseFloat(formData.tool_length),
            }
        })
            .then(response => {
                onBhaItemUpdated();
                handleClose();
            })
            .catch(error => {
                console.error("Error updating BHA item:", error);
            });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit BHA Element</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {/* Add form fields for editing */}
                    <Form.Group as={Row} controlId="bhaItemType">
                        <Form.Label column sm={4}>BHA item Type</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                value={formData.bha_type}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="od">
                        <Form.Label column sm={4}>Outer Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                value={formData.dp_size}
                                onChange={handleOdChange} required>
                                <option value="">Select OD</option>
                                {od.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    {/* Repeat similar fields for nominal weight, grade, connection, etc. */}
                    <Form.Group as={Row} controlId="nomWt">
                        <Form.Label column sm={4}>Nominal Weight</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                as="select"
                                name="dp_nom_wt"
                                value={formData.dp_nom_wt ? formData.dp_nom_wt : formData.dc_wt ? formData.dc_wt : formData.hwdp_wt_incl_tj}
                                onChange={handleNomWtChange}
                                required
                            >
                                <option value="">Select Nom. Wt.</option>
                                {Array.isArray(nomWeights) && nomWeights.length > 0 &&
                                    nomWeights.map((nomWt, index) => (
                                        <option key={index} value={nomWt.dp_nom_wt ? nomWt.dp_nom_wt : nomWt.dc_wt ? nomWt.dc_wt : nomWt.hwdp_wt_incl_tj}>
                                            {nomWt.dp_nom_wt ? nomWt.dp_nom_wt : nomWt.dc_wt ? nomWt.dc_wt : nomWt.hwdp_wt_incl_tj}
                                        </option>
                                    ))
                                }
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpId">
                        <Form.Label column sm={4}>Internal Diameter</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="number"
                                name="dp_id"
                                value={formData.dp_id}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpGrade">
                        <Form.Label column sm={4}>Grade</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.dp_grade} onChange={handleGradeChange} required>
                                <option value="">Select Grade</option>
                                {dpGrade && dpGrade.length > 0 && dpGrade.map((grade, index) => (
                                    <option key={index} value={grade}>{grade}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    {nomWeights.length > 0 && bhaType !== "HWDP" && (
                        <Form.Group as={Row} controlId="connection">
                            <Form.Label column sm={4}>Connection</Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    as="select"
                                    value={formData?.dp_conn}
                                    onChange={handleConnChange}
                                    required>

                                    <option value="">Select Connection</option>
                                    {dpConn?.map((connection, index) => (
                                        <option key={index} value={connection}>{connection}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    )}

                    {nomWeights.length > 0 && bhaType === "HWDP" && (
                        <Form.Group as={Row} controlId="connection">
                            <Form.Label column sm={4}>Connection</Form.Label>
                            <Col sm={8}>
                                <Form.Control
                                    as="select"
                                    value={formData?.dp_conn}
                                    onChange={handleConnChange}
                                    required>

                                    <option value="">Select Connection</option>
                                    {nomWeights?.map((connection, index) => (
                                        <option key={index} value={connection.hwdp_conn}>{connection.hwdp_conn}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                    )}

                    <Form.Group as={Row} controlId="class">
                        <Form.Label column sm={4}>Class</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.tool_class} onChange={(e) => setFormData({ ...formData, tool_class: e.target.value })} required>
                                <option value="">Select Class</option>
                                {dpClass.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="hwdpTjOd">
                        <Form.Label column sm={4}>Tool Joint OD</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="hwdp_conn_od"
                                value={formData?.dp_tj_od}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="hwdpTjId">
                        <Form.Label column sm={4}>Tool Joint ID</Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                type="text"
                                name="hwdp_conn_id"
                                value={formData?.dp_tj_id}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dpRange">
                        <Form.Label column sm={4}>Range</Form.Label>
                        <Col sm={8}>
                            <Form.Control as="select" value={formData.range_length} onChange={(e) => setFormData({ ...formData, range_length: e.target.value })} required>
                                <option value="">Select Range</option>
                                {dpRange.map((value, index) => (
                                    <option key={index} value={value}>{value}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="length">
                        <Form.Label column sm={4}>Length</Form.Label>
                        <Col sm={8}>
                            <Form.Control type="number" name="tool_length" value={formData.tool_length} onChange={handleChange} required />
                        </Col>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Save Changes
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditHwdpElementForm;
