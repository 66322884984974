import React, { } from "react";

export default function PricingPage() {
    // const priceTableId = process.env.REACT_STRIPE_TABLE_ID;

    return (
        <div>
            <div className="container d-flex flex-column min-vh-100">
                <div className="row h-100">
                    <div className="col-12">
                        This page is being updated ...

                        {/* <stripe-pricing-table pricing-table-id="prctbl_1QU0GiP32C6NufSHGevwxSIl"
                            publishable-key="pk_live_51Q9HaoP32C6NufSH1UHgaKsX6uHVMBwEgJLUuhHfNqUk8v9hNyhRoEdlKJG4dDaKcUxZw0cczsj0bbcpEuEAlldy00k9uxGpCU">
                        </stripe-pricing-table> */}
                        
                    </div>
                </div>
            </div>
        </div>
    );
}