import "./dashboard.scss";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { useTranslate } from 'react-admin';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useAuth } from "../../components/admin/AuthContext";
// import { GlobalStateContext } from "../../components/GlobalStateContext";

const Dashboard = () => {
    const [stats, setStats] = useState({
        userStats: null,
        companyStats: null,
        blockStats: null,
        fieldStats: null,
        wellStats: null,
        wellboreStats: null,
        designStats: null,
        onslineStats: null
    });
    const translate = useTranslate();
    const apiUrl = process.env.REACT_APP_ADMIN_API_BASE_URL;
    const { isAuthenticated, isAdmin } = useAuth();
    // const { selectedItems } = useContext(GlobalStateContext);
    // const isAdminGlobal = selectedItems?.isAdminGlobal
    console.log('isAdmin', isAdmin);

    // Reusable function to fetch stats
    const fetchStats = async (endpoint, key) => {
        try {
            const response = await axios.get(`${apiUrl}/stats/${endpoint}`, { withCredentials: true });
            setStats(prevStats => ({ ...prevStats, [key]: response.data }));
        } catch (error) {
            console.error(`Error fetching ${key}:`, error);
        }
    };

    useEffect(() => {
        if (isAuthenticated && isAdmin) {
            fetchStats('users', 'userStats');
            fetchStats('design_cases', 'designStats');
            fetchStats('companies', 'companyStats');
            fetchStats('blocks', 'blockStats');
            fetchStats('fields', 'fieldStats');
            fetchStats('wells', 'wellStats');
            fetchStats('wellbores', 'wellboreStats');
            fetchStats('online-users-count', 'onslineStats');

        }
    }, [apiUrl, isAuthenticated, isAdmin]);

    if (!isAuthenticated) return <div>Please log in to view the dashboard.</div>;
    if (!isAdmin) return <div>You do not have access to view this page.</div>;
    if (Object.values(stats).some(stat => stat === null)) return <div>Loading...</div>;

    const userChartData = stats.userStats.users_by_month.map(entry => ({
        name: entry.month,
        users: entry.count,
    }));

    const designCaseChartData = stats.designStats.designs_by_month.map(created => ({
        name: created.month,
        designs: created.count,
    }));

    const renderStatCard = (label, value) => (
        <Grid item xs={12} sm={6} md={3}>
            <Card>
                <CardContent>
                    <Typography variant="h6">{translate(label)}</Typography>
                    <Typography variant="h4">{value}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );

    return (
        <Grid container spacing={3}>
            {renderStatCard('Total Users', stats.userStats.total_users)}
            {renderStatCard('Total Logins', stats.userStats.total_login_count)}
            {renderStatCard('Users Online', stats.onslineStats.online_users_count)}
            {renderStatCard('New Users (Last 30 Days)', stats.userStats.new_users_last_30_days)}
            {renderStatCard('Total Designs', stats.designStats.total_designs)}
            {renderStatCard('New Designs (Last 30 Days)', stats.designStats.new_designs_last_30_days)}
            {renderStatCard('Total Companies', stats.companyStats.total_companies)}
            {renderStatCard('Total Blocks', stats.blockStats.total_blocks)}
            {renderStatCard('Total Fields', stats.fieldStats.total_fields)}
            {renderStatCard('Total Wells', stats.wellStats.total_wells)}
            {renderStatCard('Total Wellbores', stats.wellboreStats.total_wellbores)}

            {/* Users by Month Chart */}
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{translate('Users by Month')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={userChartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="users" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
            {/* Users by Month Chart */}
            <Grid item xs={12} sm={6} md={4}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">{translate('Designs by Month')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={designCaseChartData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="designs" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
