import React, { useState, useEffect, useContext, useMemo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { GlobalStateContext } from '../../GlobalStateContext.js';
import AddDpBhaItem from './addDpBhaItem.js';
import AddHwpBhaItem from './addHwdpBhaItem.js';
import AddDcBhaItem from './addDcBhaItem.js';
import AddCasingBhaItem from './addCasingBhaItem.js';
import { Modal, Button, Dropdown, DropdownButton, Alert } from 'react-bootstrap';

import EditBhaForm from './editBha.js';
import EditDcElementForm from './EditDcElementForm.js';
import EditHwdpElementForm from './EditHwdpElementForm.js';

import ViewBhaProperties from './viewBhaProperties.js';
import { useBhaData } from './BhaDataProvider.js';

function BhaTable(props) {
    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables
    const { fullBhaData, setFullBhaData, setBhaData, fetchBhaData } = useBhaData();

    const { selectedItems } = useContext(GlobalStateContext);
    // const [bhaData, setBhaData] = useState([]);
    // const [fullBhaData, setFullBhaData] = useState([]);

    const [cumLengths, setCumLengths] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedBhaType, setSelectedBhaType] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [bhaItemIdToDelete, setBhaItemIdToDelete] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentBhaItem, setCurrentBhaItem] = useState(null);
    const [showViewBhaPropertiesModal, setShowViewBhaPropertiesModal] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('success'); // success, danger, etc.
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedBhaItemType, setSelectedBhaItemType] = useState(null);

    const openHoleParameters = selectedItems?.openHoleParameters;

    useEffect(() => {
        // Fetch data when the component mounts
        fetchBhaData();
    }, [fetchBhaData]);

    // useEffect(() => {
    //     console.log('BHA Data:', bhaData);
    //     console.log('Full BHA Data:', fullBhaData);
    // }, [bhaData, fullBhaData]);
    // console.log('fullBhaData', fullBhaData);

    const cumulativeSums = useMemo(() => {
        let cumulativeSum = 0;
        return fullBhaData.map(bha => {
            cumulativeSum += bha.tool_length;
            return cumulativeSum;
        });
    }, [fullBhaData]);

    useEffect(() => {
        setCumLengths(cumulativeSums);
    }, [cumulativeSums]);

    const handleSelect = (eventKey) => {
        setSelectedBhaType(eventKey);
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handleBHaItemAdded = () => {
        fetchBhaData();
        handleClose();
    };

    const handleDelete = () => {
        axios({
            method: "DELETE",
            url: `${baseURL}/input/bha_items/${bhaItemIdToDelete}`,
            withCredentials: true
        })
            .then((response) => {
                if (response.status === 200) {
                    setBhaData(fullBhaData.filter(item => item.bha_item_id !== bhaItemIdToDelete));
                }
                setShowConfirmationModal(false);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                }
            });
    };

    const moveItem = (index, direction) => {
        const newBhaData = [...fullBhaData];
        const [removed] = newBhaData.splice(index, 1);
        newBhaData.splice(index + direction, 0, removed);

        // Update both bhaData and fullBhaData
        // setBhaData(newBhaData);
        setFullBhaData(newBhaData); // Assuming fullBhaData should match bhaData
    };

    // Corrected call in the saveNewOrder function:
    const saveNewOrder = () => {
        const newOrder = fullBhaData.map((item, index) => ({
            bha_item_id: item.bha_item_id,
            new_position: index
        }));

        axios.put(`${baseURL}/input/bha/update_order`, newOrder, {
            withCredentials: true
        })
            .then(response => {
                // console.log('Order saved successfully:', response.data);
                displayAlert('Order saved successfully!', 'success'); // Updated to match the function name
            })
            .catch(error => {
                console.error('Error saving order:', error.message);
                displayAlert('Error saving order. Please try again.', 'danger'); // Updated to match the function name
            });
    };

    function displayAlert(message, variant) {
        setAlertMessage(message);
        setAlertVariant(variant);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 3000);
    }

    const handleViewBhaProperties = (bhaItemId, bhaItemType) => {

        setSelectedItemId(bhaItemId);
        setSelectedBhaItemType(bhaItemType);
        setShowViewBhaPropertiesModal(true);
    };

    const handleCloseViewBhaPropertiesModal = () => {
        setShowViewBhaPropertiesModal(false);
        setSelectedItemId(null);
    };

    function getBhaItemId(bha) {
        switch (bha.type) {
            case 'Drill pipe':
                return bha.dp_size_id;
            case 'HWDP':
                return bha.hwdp_size_id;
            case 'Drill collar':
                return bha.dc_num_id;
            case 'Jar':
                return bha.jar_unique_id;
            case 'Casing':
                return bha.casing_size_id;
            default:
                return null;
        }
    }

    return (
        <div className="container h-0">
            {showAlert && (
                <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            )}
            <div className="row h-20">
                <div className="table-responsive small">
                    <p>Hole depth: {openHoleParameters.depth_md}</p>
                    <table className="table table-striped text-nowrap">
                        <thead className="border-top border-primary">
                            <tr>
                                <th className="fw-normal">Move</th>
                                <th className="fw-normal">Type</th>
                                <th className="fw-normal">OD</th>
                                <th className="fw-normal">Wt</th>
                                <th className="fw-normal">ID</th>
                                <th className="fw-normal">&Delta;L</th>
                                <th className="fw-normal">Grade</th>
                                <th className="fw-normal">Conn</th>
                                <th className="fw-normal">Class</th>
                                <th className="fw-normal">OD<sub>Tj</sub></th>
                                <th className="fw-normal">ID<sub>Tj</sub></th>
                                <th className="fw-normal">Range</th>
                                <th className="fw-normal">&Sigma;L</th>
                                <th className="fw-normal">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fullBhaData.map((bha, index) => (
                                <tr key={bha.bha_item_id}>
                                    <td>
                                        <Button
                                            variant="outline-*"
                                            size="sm"
                                            onClick={() => moveItem(index, -1)}
                                            disabled={index === 0}
                                        >
                                            <i className="bi bi-arrow-up"></i>
                                        </Button>
                                        <Button
                                            variant="outline-*"
                                            size="sm"
                                            onClick={() => moveItem(index, 1)}
                                            disabled={index === fullBhaData.length - 1}
                                        >
                                            <i className="bi bi-arrow-down"></i>
                                        </Button>
                                    </td>
                                    <td>{bha.type}</td>
                                    <td>{bha.dp_size || bha.hwdp_size || bha.dc_od || bha.jar_size || bha.casing_size}</td>
                                    <td>{bha.dp_nom_wt || bha.hwdp_wt_incl_tj || bha.dc_wt || bha.total_wt || bha.casing_wt}</td>
                                    <td>{bha.dp_id || bha.hwdp_int_dia || bha.dc_id || bha.jar_id || bha.casing_id}</td>
                                    <td>{bha.tool_length}</td>
                                    <td>{bha.dp_grade || 'G'}</td>
                                    <td>{bha.dp_conn || bha.hwdp_conn || bha.dc_number || bha.tj_conn || bha.seal}</td>
                                    <td>{bha.tool_class}</td>
                                    <td>{bha.tool_jt_od}</td>
                                    <td>{bha.tool_jt_id}</td>
                                    <td>{bha.range_length}</td>
                                    <td>{cumLengths[index]}</td>
                                    <td className="d-flex justify-content-around">
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            className="rounded-circle"
                                            onClick={() => handleViewBhaProperties(getBhaItemId(bha), bha.type)}
                                        >
                                            <i className="bi bi-eye"></i>
                                        </Button>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            className="rounded-circle"
                                            onClick={() => {
                                                setCurrentBhaItem(bha);
                                                if (bha.type === "Drill collar") {
                                                    // Show EditDcElementForm modal
                                                    setShowEditModal("dc");
                                                } else if (bha.type === "HWDP") {
                                                    // Show EditBhaForm modal
                                                    setShowEditModal("hwdp");
                                                } else {
                                                    // Show EditBhaForm modal
                                                    setShowEditModal("bha");
                                                }
                                            }}
                                        >
                                            <i className="bi bi-pencil"></i>
                                        </Button>

                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            className="rounded-circle"
                                            onClick={() => {
                                                setBhaItemIdToDelete(bha.bha_item_id);
                                                setShowConfirmationModal(true);
                                            }}
                                        >
                                            <i className="bi bi-trash"></i>
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>


                    <div className="d-flex align-items-center mt-2 mb-5">

                        <DropdownButton
                            id="dropdown-basic-button"
                            title="Add BHA Item"
                            onSelect={handleSelect}
                            style={{ marginRight: '10px' }}
                        >
                            <Dropdown.Item eventKey="Drill pipe">Drill pipe</Dropdown.Item>
                            <Dropdown.Item eventKey="HWDP">HWDP</Dropdown.Item>
                            <Dropdown.Item eventKey="Drill collar">Drill collar</Dropdown.Item>
                            <Dropdown.Item eventKey="Jar">Jar</Dropdown.Item>
                            <Dropdown.Item eventKey="Casing">Casing or Liner</Dropdown.Item>

                        </DropdownButton>

                        <Button variant="primary" onClick={saveNewOrder}>
                            Save Order
                        </Button>

                    </div>

                    {selectedBhaType === 'Drill pipe' && (
                        <AddDpBhaItem
                            show={showModal}
                            handleClose={handleClose}
                            bhaType={selectedBhaType}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onBhaItemAdded={handleBHaItemAdded}
                        />
                    )}

                    {selectedBhaType === 'Casing' && (
                        <AddCasingBhaItem
                            show={showModal}
                            handleClose={handleClose}
                            bhaType={selectedBhaType}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onBhaItemAdded={handleBHaItemAdded}
                        />
                    )}

                    {selectedBhaType === 'HWDP' && (
                        <AddHwpBhaItem
                            show={showModal}
                            handleClose={handleClose}
                            bhaType={selectedBhaType}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onBhaItemAdded={handleBHaItemAdded}
                        />
                    )}

                    {selectedBhaType === 'Drill collar' && (
                        <AddDcBhaItem
                            show={showModal}
                            handleClose={handleClose}
                            bhaType={selectedBhaType}
                            wellId={props.wellId}
                            wellboreId={props.wellboreId}
                            wellDesignCaseId={props.wellDesignCaseId}
                            onBhaItemAdded={handleBHaItemAdded}
                        />
                    )}
                </div>
            </div>
            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this BHA item?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            {showEditModal === "bha" && (
                <EditBhaForm
                    show={true}
                    handleClose={() => setShowEditModal(false)}
                    bhaElement={currentBhaItem}
                    wellId={props.wellId}
                    wellboreId={props.wellboreId}
                    wellDesignCaseId={props.wellDesignCaseId}
                    onBhaItemUpdated={fetchBhaData}
                />
            )}
            {showEditModal === "dc" && (
                <EditDcElementForm
                    show={true}
                    handleClose={() => setShowEditModal(false)}
                    bhaElement={currentBhaItem}
                    wellId={props.wellId}
                    wellboreId={props.wellboreId}
                    wellDesignCaseId={props.wellDesignCaseId}
                    onBhaItemUpdated={fetchBhaData}
                />
            )}
            {showEditModal === "hwdp" && (
                <EditHwdpElementForm
                    show={true}
                    handleClose={() => setShowEditModal(false)}
                    bhaElement={currentBhaItem}
                    wellId={props.wellId}
                    wellboreId={props.wellboreId}
                    wellDesignCaseId={props.wellDesignCaseId}
                    onBhaItemUpdated={fetchBhaData}
                />
            )}
            <ViewBhaProperties
                show={showViewBhaPropertiesModal}
                handleClose={handleCloseViewBhaPropertiesModal}
                selectedItemId={selectedItemId}
                bhaItemType={selectedBhaItemType}

            />
        </div>
    );
}

export default BhaTable;