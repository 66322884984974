import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const CookieConsent = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        // Check localStorage for user consent
        const consent = localStorage.getItem("cookieConsent");
        if (!consent) {
            setShow(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "accepted");
        setShow(false);
    };

    const handleReject = () => {
        localStorage.setItem("cookieConsent", "rejected");
        setShow(false);
    };

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>We Value Your Privacy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This website uses cookies to ensure its functionality and improve user experience.
                Essential cookies (e.g., for login and security) are always active and do not require
                consent. You can choose to accept or reject non-essential cookies for analytics and
                personalization.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleAccept}>
                    Accept
                </Button>
                <Button variant="danger" onClick={handleReject}>
                    Reject
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CookieConsent;
