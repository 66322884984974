import { useContext } from 'react';
import { GlobalStateContext } from '../GlobalStateContext';
import { useHoleSection } from '../HoleSectionProvider';
import { useBhaData } from '../well_information/bha_items/BhaDataProvider';

export default function BhaElements() {
    const { selectedItems } = useContext(GlobalStateContext);
    const drillingParameters = selectedItems?.drillingParameters;
    const bit_depth = drillingParameters.string_depth;
    console.log('bit_depth', bit_depth);

    const { selectedHoleItems } = useHoleSection();
    const { fullBhaData } = useBhaData();
    const openHoleParameters = selectedHoleItems.openHoleParameters || {};
    const casedHoleParameters = selectedHoleItems.casedHoleParameters || {};
    const bhaParameters = fullBhaData || [];

    const bhaArray = bhaParameters.map(item => parseFloat(item.tool_length));
    const pipeOdArray = bhaParameters.map(item => item.dp_size || item.hwdp_size || item.dc_od || item.jar_size || item.casing_size);
    const pipeIdArray = bhaParameters.map(item => item.dp_id || item.hwdp_int_dia || item.dc_id || item.jar_id || item.casing_id);
    const bhaTypeArray = bhaParameters.map(item => item.type);
    const pipeWeightArray = bhaParameters.map(item => item.dp_nom_wt || item.hwdp_wt_incl_tj || item.dc_wt || item.total_wt || item.casing_wt);

    const pipeOdListOh = [];
    const pipeOdList = [];
    const pipeIdList = [];
    const pipeWtList = [];
    const elemLenListOh = [];
    const elemLenListCh = [];
    const holeSizeList = [];
    const bhaTypeList = [];
    let sumElemLen = 0;

    const ohLen = openHoleParameters.depth_md > casedHoleParameters.depth_md ? bit_depth - casedHoleParameters.depth_md : 0;
    console.log('ohLen', ohLen);

    for (let i = 0; i < bhaArray.length; i++) {
        const elemLen = bhaArray[i];
        sumElemLen += elemLen;
        const pipeOd = pipeOdArray[i];
        const pipeId = pipeIdArray[i];
        const pipeWt = pipeWeightArray[i];
        const pipeType = bhaTypeArray[i];
        const holeDiam = openHoleParameters.hole_size;
        const casingIntDiam = casedHoleParameters.casing_id;

        if (sumElemLen < ohLen) {
            pipeOdListOh.push(pipeOd);

            elemLenListOh.push(elemLen);
            pipeOdList.push(pipeOd);
            pipeIdList.push(pipeId);
            pipeWtList.push(pipeWt);
            holeSizeList.push(holeDiam);
            bhaTypeList.push(pipeType);
        } else {
            const lenInsideCsg = sumElemLen - ohLen;
            const lenInsideOh = elemLen - lenInsideCsg;
            if (lenInsideOh > 0) {
                pipeOdListOh.push(pipeOd);

                elemLenListOh.push(lenInsideOh);
                pipeOdList.push(pipeOd);
                pipeIdList.push(pipeId);
                pipeWtList.push(pipeWt);
                holeSizeList.push(holeDiam);
                bhaTypeList.push(pipeType);
            }
            elemLenListCh.push(lenInsideCsg);
            pipeOdList.push(pipeOd);
            pipeIdList.push(pipeId);
            pipeWtList.push(pipeWt);
            holeSizeList.push(casingIntDiam);
            bhaTypeList.push(pipeType);
        }
    }

    // Combine open hole and cased hole lengths
    const pipeLenList = [...elemLenListOh, ...elemLenListCh];

    // Ensure the total length matches the bit depth
    let totalLength = pipeLenList.reduce((sum, len) => sum + len, 0);
    if (totalLength > bit_depth) {
        // Adjust the last element to match bit_depth
        pipeLenList[pipeLenList.length - 1] -= (totalLength - bit_depth);
    } else if (totalLength < bit_depth) {
        // Adjust the last element to match bit_depth
        pipeLenList[pipeLenList.length - 1] += (bit_depth - totalLength);
    }

    const bhaItemIndexList = Array.from({ length: pipeLenList.length }, (_, i) => i);
    console.log('holeSizeList', holeSizeList);
    console.log('pipeWtList', pipeWtList);
    console.log('pipeLenList', pipeLenList);

    return [bhaItemIndexList, pipeLenList, pipeOdList, pipeIdList, holeSizeList, bhaTypeList, pipeWtList, elemLenListOh, pipeOdListOh];
}
