
import React, { createContext, useState, useCallback, useContext } from "react";
import axios from "axios";
import { GlobalStateContext } from "../../GlobalStateContext";

// Create the context
const BhaDataContext = createContext();

export function useBhaData() {
    return useContext(BhaDataContext);
}

export function BhaDataProvider({ children, baseURL }) {
    const { selectedItems, setSelectedItems } = useContext(GlobalStateContext);

    const [bhaData, setBhaData] = useState([]);
    const [fullBhaData, setFullBhaData] = useState([]);

    // Fetch BHA Data and Additional Data
    const fetchBhaData = useCallback(() => {
        axios.get(`${baseURL}/input/bha/${selectedItems.well_id}/${selectedItems.wellbore_id}/${selectedItems.well_design_case_id}`, {
            withCredentials: true
        })
            .then(response => {
                const res = response.data;
                setBhaData(res);

                setSelectedItems((prevBha) => ({
                    ...prevBha,
                    bhaParametersUC: res,

                }));

                // Fetch additional data
                const idsToFetch = [
                    { type: 'dp_size_id', library: 'dp_library' },
                    { type: 'hwdp_size_id', library: 'hwdp_library' },
                    { type: 'dc_num_id', library: 'drill_collar_library' },
                    { type: 'jar_unique_id', library: 'jar_library' },
                    { type: 'casing_size_id', library: 'casing_library' }
                ];

                // Prepare the promises for fetching additional data
                const fetchPromises = idsToFetch.map(({ type, library }) => {
                    const ids = res.filter(bha => bha[type]).map(bha => bha[type]);
                    if (ids.length) {
                        return axios.post(`${baseURL}/input/${library}/by_ids`, { ids }, {
                            withCredentials: true
                        })
                            .then(response => response.data)
                            .catch(error => {
                                console.error(`Error fetching data from ${library}:`, error.message);
                                return []; // Return an empty array on error to keep the Promise.all structure intact
                            });
                    }
                    return Promise.resolve([]); // Return an empty array if no IDs are present
                });

                // Process the fetched data
                Promise.all(fetchPromises)
                    .then(([dpData, hwdpData, dcData, jarData, casingData]) => {
                        const updatedBhaData = res.map(bha => {
                            if (bha.dp_size_id) return { ...bha, ...dpData.find(item => item.dp_size_id === bha.dp_size_id), type: 'Drill pipe' };
                            if (bha.hwdp_size_id) return { ...bha, ...hwdpData.find(item => item.hwdp_size_id === bha.hwdp_size_id), type: 'HWDP' };
                            if (bha.dc_num_id) return { ...bha, ...dcData.find(item => item.dc_num_id === bha.dc_num_id), type: 'Drill collar' };
                            if (bha.jar_unique_id) return { ...bha, ...jarData.find(item => item.jar_unique_id === bha.jar_unique_id), type: 'Jar' };
                            if (bha.casing_size_id) return { ...bha, ...casingData.find(item => item.casing_size_id === bha.casing_size_id), type: 'Casing' };
                            return bha;
                        });

                        // Update the state with the processed data
                        setFullBhaData(updatedBhaData);

                        setSelectedItems((prevBha) => ({
                            ...prevBha,
                            fullBhaParametersUC: updatedBhaData,

                        }));
                        // console.log('updatedBhaData', updatedBhaData[0]);
                    })
                    .catch(error => {
                        console.error('Error processing additional data:', error.message);
                    });
            })
            .catch((error) => {
                console.error('Error fetching BHA data:', error.response || error.message);
            });
    }, [baseURL, selectedItems.well_design_case_id, selectedItems.well_id, selectedItems.wellbore_id, setBhaData, setFullBhaData, setSelectedItems]);

    // CRUD Operations
    const addBhaRecord = (newRecord) => {
        setBhaData((prevData) => [...prevData, newRecord]);
    };

    const updateBhaRecord = (id, updatedRecord) => {
        setBhaData((prevData) =>
            prevData.map((item) => (item.id === id ? { ...item, ...updatedRecord } : item))
        );
    };

    const deleteBhaRecord = (id) => {
        setBhaData((prevData) => prevData.filter((item) => item.id !== id));
    };

    return (
        <BhaDataContext.Provider value={{
            bhaData,
            fullBhaData,
            setFullBhaData,
            setBhaData,
            fetchBhaData,
            addBhaRecord,
            updateBhaRecord,
            deleteBhaRecord
        }}>
            {children}
        </BhaDataContext.Provider>
    );
}
