import React, { useState } from "react";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import axios from "axios";
// import { GlobalStateContext } from "../GlobalStateContext";
import { Button, Spinner } from "react-bootstrap";
import ModuleSelection from "./ModuleSelection";
import { loadStripe } from "@stripe/stripe-js";

const stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripe_public_key);


const PaymentForm = ({ selectedModules }) => {
    const stripe = useStripe();
    const elements = useElements();
    // const { selectedItems } = useContext(GlobalStateContext);
    // const userId = selectedItems?.userId;
    // const baseURL = process.env.REACT_APP_API_BASE_URL;

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const returnUrl =
        process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_RETURN_URL_PROD
            : process.env.REACT_APP_RETURN_URL_DEV;

    const handlePayment = async () => {
        if (!stripe || !elements) {
            console.error("Stripe.js has not loaded yet.");
            return;
        }

        setLoading(true);
        try {
            // First, submit the elements to validate the payment details
            const validationResult = await elements.submit();

            // If validation fails, show an error and exit early
            if (validationResult.error) {
                console.error("Validation Error:", validationResult.error.message);
                setMessage(`Payment failed: ${validationResult.error.message}`);
                setLoading(false);
                return;
            }

            // Proceed with confirming the payment after validation
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // return_url: process.env.REACT_APP_RETURN_URL,
                    return_url: returnUrl
                },
                // clientSecret,
            });

            if (error) {
                setMessage(`Payment failed: ${error.message}`);
            } else {
                setMessage("Payment succeeded! You will receive confirmation shortly.");
            }
        } catch (error) {
            console.error("Error processing payment:", error);
            setMessage(`Error: ${error.message || "Something went wrong"}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h5 className="mt-3">Enter Payment Details</h5>
            <div style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "10px" }}>
                <PaymentElement
                    id="payment-element"
                    options={{
                        layout: "accordion", // Use Stripe's accordion layout
                    }}
                />
            </div>
            <Button
                onClick={handlePayment}
                disabled={loading || !stripe || selectedModules.length === 0}
                variant="primary"
            >
                {loading ? <Spinner animation="border" size="sm" /> : "Subscribe Now"}
            </Button>
            {message && <div className="mt-3">{message}</div>}
        </div>
    );
};

const SubscriptionPage = ({ userId, stripeCustomerId }) => {
    const [selectedModules, setSelectedModules] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [clientSecret, setClientSecret] = useState(null);
    const [selectedModulesPriceIds, setSelectedModulesPriceIds] = useState([]);

    const handleModuleSubmit = async (modules, price) => {
        setSelectedModules(modules);
        setTotalPrice(price);

        if (modules.length > 0) {
            try {
                console.log('fetching client_secret in subscription');
                console.log('modules', modules);
                console.log('price', price);

                const { data } = await axios.post(
                    `${process.env.REACT_APP_API_BASE_URL}/create-stripe-subscription`,
                    { user_id: userId, module_ids: modules, stripe_customer_id: stripeCustomerId },
                    { withCredentials: true }
                );
                setClientSecret(data.client_secret);
                setSelectedModulesPriceIds(data.stripe_price_ids);
                // console.log('data.client_secret in subscription', data.client_secret);

            } catch (error) {
                console.error("Error fetching client secret:", error);
            }
        }
    };

    return (
        <div>
            <ModuleSelection onSubmit={handleModuleSubmit} userId={userId} />
            {/* Render Elements only when clientSecret is available */}
            {!clientSecret && selectedModules.length > 0 && <Spinner animation="border" />}

            {clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <PaymentForm
                        selectedModules={selectedModules}
                        totalPrice={totalPrice}
                        userId={userId}
                        clientSecret={clientSecret}
                        selectedModulesPriceIds={selectedModulesPriceIds}
                    />
                </Elements>
            )}
        </div>
    );
};

export default SubscriptionPage;

