import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { GlobalStateContext } from "../GlobalStateContext";

// Create the context
const SurveyDataContext = createContext();

// Custom hook to use the SurveyDataContext
export function useSurveyData() {
    return useContext(SurveyDataContext);
}

// Provider component
export function SurveyDataProvider({ children, token, baseURL }) {
    const { selectedItems } = useContext(GlobalStateContext);
    const [surveyData, setSurveyData] = useState([]);
    const initialEasting = selectedItems?.easting;
    const initialNorthing = selectedItems?.northing;

    const activeModules = selectedItems?.active_modules || [];
    const anyModule = ["Hydraulics", "Torque and Drag", "Kick Tolerance", "Casing Design", "Swab and Surge", "Trajectory Design"].some(module => activeModules.includes(module));

    // Function to fetch survey data
    const fetchSurveyData = useCallback(() => {
        if (!anyModule && !selectedItems.wellbore_id) {
            return;
        }
        axios({
            method: "GET",
            url: `${baseURL}/input/survey/${selectedItems.well_id}/${selectedItems.wellbore_id}`,
            withCredentials: true
        })
            .then((response) => {
                const initialData = response.data;

                if (initialData.length === 0) {
                    initialData.push(
                        {
                            md_survey: 0,
                            incl_survey: 0,
                            azim_survey: 0,
                            tvd_survey: 0,
                            dl_survey: 0,
                            dls_survey: 0,
                            easting: initialEasting,
                            northing: initialNorthing
                        },
                        {
                            md_survey: '',
                            incl_survey: '',
                            azim_survey: '',
                            tvd_survey: '',
                            dl_survey: '',
                            dls_survey: ''
                        }
                    );
                } else if (initialData.length === 1) {
                    initialData.push({
                        md_survey: '',
                        incl_survey: '',
                        azim_survey: '',
                        tvd_survey: '',
                        dl_survey: '',
                        dls_survey: ''
                    });
                }

                setSurveyData(initialData);
            })
            .catch((error) => {
                console.error('Error fetching survey data:', error);
            });
    }, [baseURL, initialEasting, initialNorthing, selectedItems.well_id, selectedItems.wellbore_id]);

    // Fetch survey data when selectedItems.well_id or selectedItems.wellbore_id changes
    useEffect(() => {
        fetchSurveyData();
    }, [selectedItems.well_id, selectedItems.wellbore_id, fetchSurveyData]);

    return (
        <SurveyDataContext.Provider value={{ surveyData, setSurveyData, fetchSurveyData }}>
            {children}
        </SurveyDataContext.Provider>
    );
}
