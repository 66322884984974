import React, { useState, useContext } from "react";
import DrillingAndFrictionFactors from "./DrillingAndFrictionFactors";
import TorqueAndDragCalculator from "./TorqueAndDragCalculator";
import { GlobalStateContext } from "../../GlobalStateContext";
import { useRheologyData } from "../../well_information/rheology/RheologyProvider";

const DrillingDesignCalculator = () => {
    const { selectedItems } = useContext(GlobalStateContext);
    const drillingParameters = selectedItems?.drillingParameters;

    const { holeSection } = useRheologyData();
    const holeSectionRheology = holeSection.find(item => item.section_type_id === selectedItems.hole_default_parameter_id);
    const rheologicalParameters = holeSectionRheology || {};
    const mw = rheologicalParameters.mwt_rheology;

    const [parameters, setParameters] = useState({
        rpm: drillingParameters.rpm,
        wob: drillingParameters.wob,
        bitTorque: drillingParameters.torque_at_bit,
        rop: drillingParameters.rop,
        spp: 0,
        blockWeight: 50000,
        mudWeight: mw,
        bitDepth: drillingParameters.string_depth,
    });

    const [frictionFactors, setFrictionFactors] = useState({
        casedHole: {
            trippingIn: 0.16,
            trippingOut: 0.16,
            rotatingOffBottom: 0.16,
            rotatingOnBottom: 0.16,
            slideDrilling: 0.16,
            backReaming: 0.16,
        },
        openHole: {
            trippingIn: 0.20,
            trippingOut: 0.20,
            rotatingOffBottom: 0.20,
            rotatingOnBottom: 0.20,
            slideDrilling: 0.20,
            backReaming: 0.20,
        },
        range: {
            trippingIn: 0.0,
            trippingOut: 0.0,
            rotatingOffBottom: 0.0,
            rotatingOnBottom: 0.0,
            slideDrilling: 0.0,
            backReaming: 0.0,
        },
    });

    const [selectedFactors, setSelectedFactors] = useState({
        trippingIn: true,
        trippingOut: true,
        rotatingOffBottom: true,
        rotatingOnBottom: false,
        slideDrilling: false,
        backReaming: false,
    });

    // Handler Functions
    const handleParameterChange = (e) => {
        const { name, value } = e.target;
        setParameters((prev) => ({ ...prev, [name]: value }));
    };

    const handleFrictionChange = (e, category, activity) => {
        const { value } = e.target;
        setFrictionFactors((prev) => ({
            ...prev,
            [category]: { ...prev[category], [activity]: parseFloat(value) || 0 },
        }));
    };

    const handleCheckboxChange = (e, activity) => {
        const { checked } = e.target;
        setSelectedFactors((prev) => ({ ...prev, [activity]: checked }));
    };

    return (
        <div>
            {/* Pass state and handlers to child components */}
            <DrillingAndFrictionFactors
                parameters={parameters}
                frictionFactors={frictionFactors}
                selectedFactors={selectedFactors}
                handleParameterChange={handleParameterChange}
                handleFrictionChange={handleFrictionChange}
                handleCheckboxChange={handleCheckboxChange}
            />
            <TorqueAndDragCalculator
                parameters={parameters}
                frictionFactors={frictionFactors}
                selectedFactors={selectedFactors}
            />
        </div>
    );
};

export default DrillingDesignCalculator;
