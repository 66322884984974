import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter} from 'react-router-dom';
import { AuthProvider } from './components/admin/AuthContext';
import AppContent from './AppContent';
import Footer from './pages/tabs_components/Footer';
import { GlobalStateProvider } from './components/GlobalStateContext';
import { BhaDataProvider } from './components/well_information/bha_items/BhaDataProvider';
import { RheologyDataProvider } from './components/well_information/rheology/RheologyProvider';
import { CoordinateConversionProvider } from './components/trajectory/CoordinateConversionProvider';
import { SurveyDataProvider } from './components/trajectory/SurveyDataProvider';
import { HoleSectionProvider } from './components/HoleSectionProvider';
import { EarthModelProvider } from './components/design_headers/EarthModelContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CasingProvider } from './components/modules/casing_design/CasingProvider';
import CookieConsent from './components/admin/CookieConsent';
import { ModulesProvider } from './components/admin/ModulesContext';
import { BitDataProvider } from './components/well_information/bits/BitDataProvider';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripe_public_key);

function App() {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  // const [clientSecret, setClientSecret] = useState("");
  // const [dpmCheckerLink, setDpmCheckerLink] = useState("");

  useEffect(() => {
    const recaptchaSiteKey = 'process.env.REACT_APP_RECAPTCHA_SITE_KEY';

    const existingScript = document.querySelector(`script[src*="https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}"]`);

    // Check if the script is already in the document
    if (!existingScript) {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      document.head.appendChild(recaptchaScript);
    }
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  // Enable the skeleton loader UI for optimal loading.
  const loader = 'auto';

  return (
    <GlobalStateProvider>
      <AuthProvider>
        <BrowserRouter>
          
          <CoordinateConversionProvider baseURL={baseURL}>
            <ModulesProvider>
            <SurveyDataProvider baseURL={baseURL}>
              <HoleSectionProvider baseURL={baseURL}>
                <RheologyDataProvider baseURL={baseURL}>
                  <BhaDataProvider baseURL={baseURL}>
                    <EarthModelProvider>
                      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                          <CasingProvider>

                            <BitDataProvider baseURL={baseURL}>
                          <Elements options={{ appearance, loader }} stripe={stripePromise}>
                            <Suspense fallback={<div>Loading...</div>}>
                              <CookieConsent />
                              <AppContent />
                            </Suspense>
                              </Elements>
                              </BitDataProvider>
                        </CasingProvider>
                      </GoogleReCaptchaProvider>

                    </EarthModelProvider>
                  </BhaDataProvider>
                </RheologyDataProvider>
              </HoleSectionProvider>
              </SurveyDataProvider>
            </ModulesProvider>
          </CoordinateConversionProvider>
          
        <Footer />
        </BrowserRouter>
      </AuthProvider>
    </GlobalStateProvider>
  );
}

export default App;

