import { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown, Button, Dropdown, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import logo from '../../images/logo.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHoleSection } from "../../components/HoleSectionProvider";
import { ModulesContext } from "../../components/admin/ModulesContext";

function LoggedInLinks() {
    const { holeSection } = useHoleSection();
    const { activeModules } = useContext(ModulesContext);

    return (
        <>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">Applications</Nav.Link>
            <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>
            <NavDropdown title="Design Inputs" id="new_design-nav-dropdown">
                <NavDropdown.Item as={Link} to="/welcome">Dashboard</NavDropdown.Item>

                <NavDropdown.Item
                    as={Link}
                    to="/design_case"
                    disabled={!activeModules.length > 0 }
                >Design case</NavDropdown.Item>
                <NavDropdown.Item
                    as={Link}
                    to="/well_information"
                    disabled={!holeSection.length > 0 || !activeModules.length > 0}
                >Well information</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Modules" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/trajectory">Well Trajectory Design</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/hydraulics">Hydraulics</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/swab_and_surge">Swab and Surge</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/torque_and_drag" >Torque and Drag</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/kick_tolerance">Kick Tolerance</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/casing_design">Casing Design</NavDropdown.Item>

            </NavDropdown>
            <NavDropdown title="Tools" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/units">Units converter</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/casing_dimensions_strength">Casing dimensions and strengths</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/drill_pipe_dimensions_strength">Drill pipe dimensions and strengths</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/capacities">Capacities</NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item
                    as="span"
                    className={!activeModules.includes('Torque and Drag') ? 'disabled-link' : ''}>
                    {activeModules.includes('Torque and Drag') ? (
                        <Link to="/pipe_stretch">Pipe stretch and collapse</Link>
                    ) : (
                        'Pipe stretch and collapse'
                    )}
                </NavDropdown.Item>
                <NavDropdown.Item
                    as="span"
                    className={!activeModules.includes('Casing Design') ? 'disabled-link' : ''}>
                    {activeModules.includes('Casing Design') ? (
                        <Link to="/casing_wear">Casing Wear</Link>
                    ) : (
                        'Casing Wear'
                    )}
                </NavDropdown.Item>


                <NavDropdown.Divider />
                <NavDropdown.Item
                    as="span"
                    className={!activeModules.includes('Hydraulics') ? 'disabled-link' : ''}>
                    {activeModules.includes('Hydraulics') ? (
                        <Link to="/pressure">Annular Pressure Loss</Link>
                    ) : (
                        'Annular Pressure Loss'
                    )}
                </NavDropdown.Item>
                <NavDropdown.Item
                    as="span"
                    className={!activeModules.includes('Hydraulics') ? 'disabled-link' : ''}>
                    {activeModules.includes('Hydraulics') ? (
                        <Link to="/compressibility">Mud compressibility</Link>
                    ) : (
                        'Mud compressibility'
                    )}
                </NavDropdown.Item>
                {/* <NavDropdown.Item as={Link} to="/temperature">Temperature calculations</NavDropdown.Item> */}
                <NavDropdown.Item
                    as="span"
                    className={!activeModules.includes('Hydraulics') ? 'disabled-link' : ''}>
                    {activeModules.includes('Hydraulics') ? (
                        <Link to="/temperature">Temperature calculations</Link>
                    ) : (
                        'Temperature calculations'
                    )}
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item
                    as="span"
                    className={!activeModules.includes('Trajectory Design') ? 'disabled-link' : ''}>
                    {activeModules.includes('Trajectory Design') ? (
                        <Link to="/interpolation">Interpolate surveys</Link>
                    ) : (
                        'Interpolate surveys'
                    )}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                    as="span"
                    className={!activeModules.includes('Kick Tolerance') ? 'disabled-link' : ''}>
                    {activeModules.includes('Kick Tolerance') ? (
                        <Link to="/gas_migration">Gas migration</Link>
                    ) : (
                        'Gas migration'
                    )}
                </NavDropdown.Item>                
            </NavDropdown>
        </>
    );
}

function LoggedOutLinks() {
    return (
        <>
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">Applications</Nav.Link>
            <Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>

        </>
    );
}

function LoggedOutLinksRight() {
    return (
        <>
            <Button className="btn btn-outline-light" as={Link} to="/login">Login</Button>
            <Button variant="primary" as={Link} to="/register">Sign Up</Button>
        </>
    );
}

function Header({ setIsTwoFactorEnabled, isAuthenticated, setIsAuthenticated, isTwoFactorVerified, setIsTwoFactorVerified, email, setEmail, userId, setUserId, fullName, setFullName, isAdmin, setIsGlobalAdmin }) {

    const baseURL = process.env.REACT_APP_API_BASE_URL; // Get base URL from environment variables
    const navigate = useNavigate();
    
    async function handleLogout() {
        try {
            await axios.post(
                `${baseURL}/logout`,
                {},
                {
                    withCredentials: true, // Include credentials to ensure the HttpOnly cookie is included
                }
            );
            setIsAuthenticated(false);
            setIsTwoFactorVerified(false);

            setUserId('');
            setIsTwoFactorEnabled(false);
            setIsTwoFactorVerified(false);
            setEmail('');
            setFullName('');
            setIsGlobalAdmin('');

            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }

    return (
        <Navbar expand="lg" bg="primary" data-bs-theme="dark" sticky="top">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    WellDesigner
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {!isTwoFactorVerified ? (
                            <LoggedOutLinks />
                        ) : (
                            <LoggedInLinks />
                        )}
                    </Nav>
                    {!isTwoFactorVerified ? (
                        <>
                            <LoggedOutLinksRight />
                        </>
                    ) : (
                            <>
                                <Nav className="align-items-center text-light">
                                    
                                    {fullName ? fullName : email}

                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary">
                                            {/* <span className="material-symbols-outlined rounded-circle" id="id_profile_links" >
                                                person
                                            </span> */}
                                            {/* <AccountCircleIcon fontSize="large" /> */}
                                            {<AccountCircleIcon />} 

                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item as={Link} to="/my_account">
                                                My account
                                            </Dropdown.Item>
                                            <Dropdown.Item as={Link} to="/design_case">
                                                My Designs
                                            </Dropdown.Item>
                                            {isAdmin && (
                                                <Dropdown.Item as={Link} to="/admin">
                                                    My Admin
                                                </Dropdown.Item>
                                            )}
                                            <Dropdown.Item onClick={handleLogout}>
                                                Logout
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Link to="/subscribe" className="text-light ms-2">
                                        <ShoppingCartIcon />
                                    </Link>
                                </Nav>
                            </>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;